import { openDrawerTab } from 'components/_common/Drawer';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  activateWorkflow,
  deactivateWorkflow
} from 'redux/actions/workflowActions';
import { getWorkflowSteps, isWorkflowValid } from 'redux/selectors';
import './Toolbar.scss';

const Toolbar = (props) => {
  const { workflow } = props;

  const dispatch = useDispatch();
  const valid = useSelector((state) =>
    isWorkflowValid(state, '' || workflow.id)
  );
  const workflowSteps = useSelector(getWorkflowSteps);
  useEffect(() => {
    if (!valid && workflow.active) {
      toast.info('Workflow deactivated');
      dispatch(deactivateWorkflow(workflow));
    }
    if (workflowSteps && workflowSteps.length && valid && !workflow.active) {
      toast.info('You can now activate the workflow');
    }
  }, [valid]);

  function toggleActivation(e) {
    e.preventDefault();
    if (workflow.active) {
      toast.info('Workflow deactivated');
      dispatch(deactivateWorkflow(workflow));
    } else {
      toast.info('Workflow activated');
      dispatch(activateWorkflow(workflow));
    }
  }

  function render() {
    return (
      <nav
        className="navbar workflowEditor_toolbar has-background-white-ter "
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-brand">
          {/* <div className="navbar-item">
          <Link className='button is-rounded is-link is-small' to='/workflows'><i class="fas fa-arrow-left"></i></Link>
        </div> */}
          <div className="navbar-item">
            {/* {
            editorMode === 'editor' ? <SelectorButtons /> : null
          } */}
          </div>
        </div>
        <div class="navbar-start is-centered">
          <div class="navbar-item workflow_title">
            <p
              onClick={(e) => {
                dispatch(openDrawerTab('workflow_editor', 'Workflow Settings'));
              }}
            >
              {workflow.name ? workflow.name : 'Draft'}
            </p>
          </div>
        </div>
        <div class="navbar-end">
          <div class="navbar-item">{/* <EditorOptions /> */}</div>
          <div class="navbar-item">
            <div class="field">
              <input
                id="activateWorkflow"
                type="checkbox"
                name="activateWorkflow"
                class="switch is-rounded is-success"
                onChange={toggleActivation}
                checked={workflow.active ? true : false}
                disabled={valid ? false : true}
              />
              <label for="activateWorkflow"></label>
            </div>
          </div>
        </div>
      </nav>
    );
  }
  return render();
};

export default Toolbar;
