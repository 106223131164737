import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const CircularProgress = ({ classes, value }) => {
  return (
    <div
      class={`progress-circle ${classes || ''}`}
      style={{ width: 25, height: 25 }}
    >
      <CircularProgressbar value={value} strokeWidth={14} />
    </div>
  );
};
export default CircularProgress;
