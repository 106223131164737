import React from 'react';
import QueryBuilder from 'react-querybuilder';
import { AddRuleAction, RemoveRuleAction } from './components/Buttons';
import ValueEditor from './components/ValueEditor';
import InputEditor from '../InputEditor'
import ReactTooltip from 'react-tooltip';

const Logic = (props) => {

  const { fields, index, formula, getOperators } = props;
  const { onQueryChange, onExpressionChange, onDelete, onTypeChange } = props;
  const fieldOptions = []
  fields.forEach(i => {
    if (!['signature'].includes(i.data.type))
      fieldOptions.push({ value: i.data.name, label: i.data.name })
  });

  return (
    <div class={`formula_container ${props.className} ${props.index % 2 === 0 ? 'even' : 'odd'}`}>
      <div className='field font_select is-horizontal'>
        <div class="field-label is-small">
          <label class="label">Condition Type</label>
        </div>
        <div class="field-body">
          <p class="control is-expanded select_control">
            <span class="select is-small is-fullwidth">
              <select id={`logic-select-condition-type-${index}`} value={formula.type} onChange={e => { onTypeChange(index, e.target.value) }}>
                <option value='show'>Show this field</option>
                <option value='hide'>Hide this field</option>
                {props.field.data.type !== 'signature' && <option value='expression'>Set value or formula</option>}
              </select>
            </span>
            {props.tooltip && <i class="fas fa-question-circle" data-tip={props.tooltip}></i>}
          </p>
        </div>
      </div>

      {formula.type === 'expression' && <><span className='content is-small'>Value or Formula</span>
        <InputEditor
          fields={fieldOptions}
          value={formula.expression}
          multiple={true}
          placeholder='Value or Formula Expression'
          onChange={value => onExpressionChange(index, value)} />
      </>}

      {props.tooltip && <i class="fas fa-question-circle" data-tip={props.tooltip}></i>}

      <div className='query_builder field'>
        <QueryBuilder
          {...{ query: Object.keys(formula.query).length === 0 ? null : formula.query }}
          onQueryChange={query => onQueryChange(index, query)}
          combinators={[{ name: 'and', label: 'All' }, { name: 'or', label: 'Any' }]}
          translations={{ addRule: { label: "Add Rule", title: "Add rule" } }}
          fields={fields}
          controlElements={{
            addRuleAction: AddRuleAction,
            removeRuleAction: RemoveRuleAction,
            valueEditor: ValueEditor
          }}
          getOperators={getOperators}
          showCombinatorsBetweenRules={false}
        />
      </div>
      <a className='delete_button' onClick={e => onDelete(index, formula)}>
        <i class="fas fa-trash"></i><span> Delete</span>
      </a>
      <ReactTooltip className='field_tooltip' place='left' effect='solid' offset={{ top: 0 }} multiline={true} />
    </div>
  );
}

export default Logic;