import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setCurrentSelector } from '../../../../redux/actions';
import { getCurrentSelector } from '../../../../redux/selectors';
import { GroupField } from './GroupFields';
class SelectorButtons extends Component {
  render() {
    let value = this.props.currentSelector;
    let onChange = this.props.setCurrentSelector;

    return (
      <div class="field has-addons is-fullwidth">
        <p class="control">
          <button
            type="button"
            class={`button is-small ${
              value === 'text' ? 'is-info is-active' : ''
            }`}
            onClick={(e) => {
              onChange('text');
            }}
          >
            <span class="icon is-small">
              <i class="fas fas fa-font"></i>
            </span>
            <span>Text</span>
          </button>
        </p>

        <p class="control">
          <button
            type="button"
            class={`button is-small ${
              value === 'number' ? 'is-info is-active' : ''
            }`}
            onClick={(e) => {
              onChange('number');
            }}
          >
            <span class="icon is-small">
              <i class="fas fa-hashtag"></i>
            </span>
            <span>Number</span>
          </button>
        </p>

        <p class="control">
          <button
            type="button"
            class={`button is-small ${
              value === 'checkbox' ? 'is-info is-active' : ''
            }`}
            onClick={(e) => {
              onChange('checkbox');
            }}
          >
            <span class="icon is-small">
              <i class="fas fa-check"></i>
            </span>
            <span>Checkbox</span>
          </button>
        </p>

        <p class="control">
          <button
            type="button"
            class={`button is-small ${
              value === 'dateTime' ? 'is-info is-active' : ''
            }`}
            onClick={(e) => {
              onChange('dateTime');
            }}
          >
            <span class="icon is-small">
              <i class="far fa-calendar-alt"></i>
            </span>
            <span>Date</span>
          </button>
        </p>

        <p class="control">
          <button
            type="button"
            class={`button is-small ${
              value === 'signature' ? 'is-info is-active' : ''
            }`}
            onClick={(e) => {
              onChange('signature');
            }}
          >
            <span class="icon is-small">
              <i class="fas fa-signature"></i>
            </span>
            <span>Signature</span>
          </button>
        </p>
        <p class="control">
          <button
            type="button"
            class={`button is-small ${
              value === 'image' ? 'is-info is-active' : ''
            }`}
            onClick={(e) => {
              onChange('image');
            }}
          >
            <span class="icon is-small">
              <i class="far fa-image"></i>
            </span>
            <span>Image</span>
          </button>
        </p>
        <GroupField />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentSelector: getCurrentSelector(state)
});

export default connect(mapStateToProps, { setCurrentSelector })(
  SelectorButtons
);
