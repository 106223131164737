import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getWorkflowSteps } from 'redux/selectors';

const useExposedFields = (order, parentDocument, currentValues) => {
  const steps = useSelector(getWorkflowSteps).sort((a, b) => a.order - b.order);
  const [fields, updateFields] = useState({});
  useEffect(() => {
    let tempFields = {};

    if (order > 1) {
      steps.forEach((step) => {
        if (step.order < order && step.data.exposedFields) {
          tempFields[step.order] = step.data.exposedFields;
        }
      });
    }
  
    // If parentDocument is passed (i.e. a form step) , add fields from the document as well.
    if (parentDocument) {
      let docFields = parentDocument.fields.filter((f) =>
        ['text', 'number', 'dateTime'].includes(f.data.type)
      );
      // If only certain fields are selected, keep only those fields.
      if (
        currentValues &&
        currentValues['displayFields'].value === 'selected'
      ) {
        let selectedFields = Array.isArray(currentValues['selectedFields'])
          ? currentValues['selectedFields'].map((f) => f.value)
          : docFields.map((f) => f.data.name);
        docFields = docFields.filter((f) =>
          selectedFields.includes(f.data.name)
        );
      }
      tempFields[order] = docFields.map((f) => ({
        type: 'field',
        name: f.data.name,
        label: f.data.label,
        step: order
      }));
    }
    updateFields(tempFields);
  }, [currentValues]);
  return fields;
};
export default useExposedFields;
