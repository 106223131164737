import React, { Component } from 'react';

class HorizontalAlignment extends Component {
  render() {
    let { value, onChange } = this.props.input;
    return (
      <div className="field is-horizontal">
        <div class="field-label is-small">
          <label class="label">Alignment</label>
        </div>
        <div class="field-body">
          <div class="field has-addons is-fullwidth">
            <p class="control">
              <button
                type="button"
                class={`button is-small ${value === 'left' ? 'is-active' : ''}`}
                onClick={(e) => {
                  onChange('left');
                }}
              >
                <span class="icon is-small">
                  <i class="fas fa-align-left"></i>
                </span>
              </button>
            </p>
            <p class="control">
              <button
                type="button"
                class={`button is-small ${
                  value === 'center' ? 'is-active' : ''
                }`}
                onClick={(e) => {
                  onChange('center');
                }}
              >
                <span class="icon is-small">
                  <i class="fas fa-align-center"></i>
                </span>
              </button>
            </p>
            <p class="control">
              <button
                type="button"
                class={`button is-small ${
                  value === 'right' ? 'is-active' : ''
                }`}
                onClick={(e) => {
                  onChange('right');
                }}
              >
                <span class="icon is-small">
                  <i class="fas fa-align-right"></i>
                </span>
              </button>
            </p>
          </div>
        </div>
      </div>
    );
  }
}
export default HorizontalAlignment;
