import useDimensions from 'hooks/useDimensions';
import { isEmpty, isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import EditableLabel from 'react-inline-editing';
import { useDispatch, useSelector } from 'react-redux';
import { Field, getFormValues, isValid, reduxForm } from 'redux-form';
import { required } from 'redux-form-validators';
import { saveStepToServer, updateStep } from 'redux/actions/workflowActions';
import { getWorkflowSteps, isActionPending } from 'redux/selectors';
import { validFileName } from 'utils/validators';
import { FieldSelect, ReSelect } from '../components/Select';
import useExposedFields from './useExposedFields';

const SmartVaultSettingsComponent = (props) => {
  const step = props.step;

  const [name, setName] = useState(step.name || props.defaultName);

  const valid = useSelector((state) => isValid(props.form)(state));

  const accounts = useSelector(
    (state) => state.apps.all.filter((a) => a.type === 'smartvault'),
    isEqual
  );
  const accountsOptions = [];
  accounts.forEach((e) => {
    if (e.status === 'active') {
      accountsOptions.push({ label: e.name, value: e.id, type: e.type });
    }
  });

  const pendingUpdate = useSelector((state) =>
    isActionPending(state, 'UPDATE_STEP')
  );

  const currentValues = useSelector((state) =>
    getFormValues(props.form)(state)
  );

  const dispatch = useDispatch();
  const prevData = useExposedFields(step.order);
  const [ref, { x }] = useDimensions(true);

  const steps = useSelector(getWorkflowSteps).sort((a, b) => a.order - b.order);

  useEffect(() => {
    props.initialize(props.initialValues);
  }, []);

  // Autofill generate filename field from previous document step.
  useEffect(() => {
    const curFilename = currentValues['fileName'];
    if (!curFilename || curFilename.length === 0) {
      const prevStep = steps
        .sort((a, b) => b.order - a.order)
        .filter((s) => s.order < step.order)
        .filter((s) => ['document', 'intermediateForm'].includes(s.type))[0];

      let fileName = prevStep?.data?.stepData?.fileName;
      // Need to do this for backwards compatibility.
      if (fileName) fileName = fileName.replaceAll('{{0.', '{{1.');
      props.change('fileName', fileName);
      if (!isEmpty(prevStep.data)) {
        props.change('attachment', `{{${prevStep.order}.generatedDocument}}`);
      }
    }
  }, [steps, currentValues]);

  function submit(values) {
    function createUpdatedStep(values) {
      const { smartvaultAccount, attachment, fileName } = values;

      const newStep = {
        ...step,
        name,
        data: {
          ...step.data,
          valid,
          stepData: {
            smartvaultAccount,
            attachment,
            fileName
          },
          stepSettings: {
            smartvaultAccount,
            attachment,
            fileName
          },
          exposedFields: []
        }
      };
      return newStep;
    }

    const newStep = createUpdatedStep(values);
    dispatch(updateStep(newStep));
    dispatch(saveStepToServer(newStep));
  }

  const Topbar = ({ name, setName }) => {
    return (
      <div className="step_bar">
        <nav class="level">
          <div class="level-left step-editable-name">
            <div class="level-item">
              <span className="step_name" title="Click to edit name">
                <Field
                  name="stepName"
                  value={name}
                  component={(props) => (
                    <>
                      <EditableLabel
                        onFocusOut={(text) => {
                          setName(text);
                          props.input.onChange(name);
                        }}
                        inputClassName="input"
                        text={name}
                      />
                    </>
                  )}
                />
              </span>
            </div>
            <div class="level-item">
              <i class="fa fa-edit" />
            </div>
          </div>
          <div class="level-right">
            <button
              type="submit"
              disabled={pristine}
              className={`button is-small is-info ${
                pendingUpdate && pendingUpdate.pending ? 'is-loading' : ''
              }`}
            >
              Save
            </button>
          </div>
        </nav>
      </div>
    );
  };

  const { handleSubmit, pristine } = props;
  let formFields = [];
  if (prevData['1']) {
    prevData['1'].forEach((d) => {
      if (d) {
        if (d.type === 'field')
          formFields.push({ value: d.name, label: d.name });
      }
    });
  }

  return (
    <div className="step_setting" ref={ref}>
      <form onSubmit={handleSubmit(submit)}>
        <Topbar name={name} setName={setName} />
        <div className="fields">
          <label class="label">
            SmartVault Account <span className="has-text-grey">(required)</span>
          </label>
          <div class="field is-grouped">
            <div class="control is-expanded">
              <Field
                name="smartvaultAccount"
                className="from is-fullwidth"
                component={ReSelect}
                validate={[
                  required({
                    msg: 'Please select a SmartVault account to use.'
                  })
                ]}
                options={accountsOptions}
                placeholder="SmartVault Account"
              />
            </div>
          </div>
          <p class="help pull-up">Select the SmartVault account to use.</p>

          <div class="field">
            <label class="label">File</label>
            <div class="control">
              <Field
                name="attachment"
                component={FieldSelect}
                showMenuOnTouch={true}
                width={x}
                options={prevData}
                validate={[
                  required({
                    msg: 'Please select the file to be uploaded.'
                  })
                ]}
                exclusive={true}
                typeFilter="attachment"
                openMenuOnClick={false}
                placeholder="File"
              />
            </div>
            <p class="help">
              Click to select file generated from previous steps.
            </p>
          </div>

          <label class="label">
            Generated File Name{' '}
            <span className="has-text-grey">(required)</span>
          </label>
          <div className="field">
            <p className="control">
              <Field
                name="fileName"
                component={FieldSelect}
                width={x}
                multiple=" "
                options={prevData}
                typeFilter="field"
                validate={[
                  validFileName,
                  required({
                    msg: 'Please provide a filename for the uploaded document.'
                  })
                ]}
              />
            </p>
            <p class="help">
              Alpha numeric characters with hyphens and underscores only. File
              extension ".pdf" will be added automatically.
            </p>
          </div>
        </div>
      </form>
    </div>
  );
};

const SmartVaultSettings = reduxForm({ initialValues: {} })(
  SmartVaultSettingsComponent
);
export default SmartVaultSettings;
