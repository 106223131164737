import 'components/_common/FormFields/ImageField/ImageField.scss';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;
const maxSize = 10485760;

const ImageField = (props) => {
  const {
    meta: { touched, error, warning },
    input: { onChange, value }
  } = props;
  const [image, setImage] = useState(value);
  const [uploading, setUploading] = useState(false);
  const [key, setKey] = useState(0);

  useEffect(() => {
    props.input.onChange(image);
    if (props.track) props.track(image);
  }, [image]);

  const onDrop = async (files) => {
    const { type } = files[0];
    // Base64
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = function () {
      setImage({ base64: reader.result, type });
      onChange(image);
    };
    reader.onerror = function (error) {
      console.log(error);
    };
  };

  const {
    isDragActive,
    getRootProps,
    getInputProps,
    isDragReject,
    acceptedFiles,
    rejectedFiles,
    inputRef
  } = useDropzone({
    onDrop,
    minSize: 0,
    maxSize,
    canRemove: true,
    accept: 'image/png, image/jpeg'
  });

  const isFileTooLarge =
    rejectedFiles &&
    rejectedFiles.length > 0 &&
    rejectedFiles[0].size > maxSize;
  return (
    <div className="field" style={props.hidden ? { display: 'none' } : {}}>
      <label className="label ">
        {props.label}{' '}
        {props.required && (
          <span className="has-text-grey">
            ({props.requiredLabel || 'required'})
          </span>
        )}
      </label>
      <div className="control image-uploader">
        {!!!(image && image.base64) ? (
          <div class="image-uploader-drag">
            <div
              key={key}
              {...getRootProps()}
              class={`uploader ${
                isDragActive && !isDragReject ? 'active' : 'not-active'
              }`}
            >
              <input {...getInputProps()} />
              {!isDragActive && (
                <p>Click here or drop an image file to upload!</p>
              )}
              {isDragReject && (
                <p className="help is-danger">
                  File type not accepted, please use a PNG or JPG file!
                </p>
              )}
              {isFileTooLarge && (
                <p className="help is-danger">
                  File is too large, please use a file smaller than 10MB!
                </p>
              )}
            </div>
          </div>
        ) : (
          <div class="image-uploader-file">
            {image && image.base64 && (
              <div>
                <img src={image.base64} class="image-preview" />
                <button
                  type="button"
                  className="image_upload_clear button  is-fullwidth"
                  onClick={() => setImage(null)}
                >
                  <i class="far fa-trash-alt" />
                </button>
              </div>
            )}
          </div>
        )}
      </div>
      {props.description && <p className="help">{props.description}</p>}
      <p className="help is-danger">
        {touched &&
          ((error && <span>{error}</span>) ||
            (warning && <span>{warning}</span>))}
      </p>
    </div>
  );
};
export default ImageField;
