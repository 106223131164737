
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';

export function GlobalSearch({
  globalFilter,
  setGlobalFilter,
}) {
  return (
    <div class="control has-icons-left has-icons-right">
      <input
        class="input" type="text"
        value={globalFilter || ''}
        onChange={e => {
          setGlobalFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
        }}
        placeholder={`Search...`}
      />
      <span class="icon is-small is-left">
        <i class="fas fa-search"></i>
      </span>
    </div>
  )
}
export function SelectFilter({ id, placeholder, className, headerGroup }) {

  const column = headerGroup.headers.find(h => h.id === id)
  const { filterValue, setFilter, preFilteredRows } = column
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  // Render a multi-select box
  return (
    <div class="select">
      <select
        value={filterValue}
        className={className}
        onChange={e => {
          setFilter(e.target.value || undefined)
        }}>
        <option value="">{placeholder}</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  )
}


export const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <input className='checkbox' type="checkbox" ref={resolvedRef} {...rest} />
      </>
    )
  }
)

export function SortSelect({ value, placeholder, className, options, onChange }) {

  return (
    <div class="select">
      <select
        value={value}
        className={className}
        onChange={onChange}>
         <option value="">{placeholder || 'Sort By'}</option>
        {options.map(option => (
           <option value={option.value}>{option.label}</option>
        ))}
      </select>
    </div>
  )
}