import React, { useState } from 'react';

const CollapsibleCard = (props) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <div class="card is-fullwidth" style={{ minWidth: '100%' }}>
      <header
        class="card-header"
        style={{ cursor: 'pointer' }}
        onClick={() => {
          setExpanded(!!!expanded);
        }}
      >
        <p class="card-header-title">{props.title}</p>
        <a
          href={`#${props.id}`}
          data-action="collapse"
          class="card-header-icon is-hidden-fullscreen"
          aria-label="more options"
        >
          <span class="icon">
            <i class="fas fa-angle-down" aria-hidden="true"></i>
          </span>
        </a>
      </header>
      {expanded && (
        <div id={props.id} class="is-collapsible">
          <div style={{ padding: '20px' }}>{props.children}</div>
        </div>
      )}
    </div>
  );
};

export default CollapsibleCard;
