/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';

import useOnClickOutside from 'hooks/useOnClickOutside'
import Editor from './Editor'


const InputEditor = props => {
  const { value, multiple, exclusive, placeholder, message } = props
  const [currentValue, setCurrentValue] = useState(value || '')
  const [showMenu, updateShowMenu] = useState(false)
  const [index, setIndex] = useState(0)

  let [options, setOptions] = useState(props.options)
  let [fields, setFields] = useState(props.fields)

  let [dropdownItems, setDropdownItems] = useState([])

  let highlights = ['dateOfSubmission', 'true', 'false']
  if (props.fields) highlights = [...highlights, ...props.fields.map(i => i.value)]

  useEffect(() => {
    if (options && currentValue) setOptions(props.options.filter(i => i.value && i.value.startsWith(currentValue.split(' ').pop())))
    if (fields && currentValue) setFields(props.fields.filter(i => i.value && i.value.startsWith(currentValue.split(' ').pop())))
    if (currentValue === '') {
      setOptions(props.options)
      setFields(props.fields)
    }
  }, [currentValue])

  useEffect(() => {
    let items = []
    items = options ? [...items, ...options] : [...items]
    items = fields ? [...items, ...fields] : [...items]
    setDropdownItems(items)
  }, [options, fields])

  const showDropdown = (options && options.length > 0) || ((fields && fields.length > 0))

  useEffect(() => {
    if (!showMenu) setIndex(0)
  }, [showMenu])

  function onKeyUp(e) {
    if (exclusive) {
      if (e.keyCode === 8) setCurrentValue('')
    }
  }
  const onKeyDown = event => {
    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault()
        const prevIndex = index >= dropdownItems.length - 1 ? 0 : index + 1
        setIndex(prevIndex)
        break
      case 'ArrowUp':
        event.preventDefault()
        const nextIndex = index <= 0 ? dropdownItems.length - 1 : index - 1
        setIndex(nextIndex)
        break
      case 'Tab':
      case 'Enter':
        event.preventDefault()
        handleOptionClick(dropdownItems[index].value)
        setIndex(0)
        break
      case 'Escape':
        event.preventDefault()
        updateShowMenu(false)
        break
      default: break
    }
  }
  const handleInputChange = value => {
    setCurrentValue(value)
    props.onChange(value)
  }
  const handleOptionClick = value => {
    let newValue;
    if (multiple) newValue = currentValue ? `${currentValue.split(' ').slice(0, -1).join(' ')} ${value}` : `${value}`
    else newValue = `${value}`
    setCurrentValue(newValue)
    props.onChange(newValue)
    updateShowMenu(!showMenu)
  }
  const ref = useRef()
  useOnClickOutside(ref, () => { updateShowMenu(false) })

  return (
    <div className='field input_editor' ref={ref}>
      <div class="control">
        <Editor
          value={currentValue}
          highlights={highlights}
          onChange={handleInputChange}
          onKeyDown={onKeyDown}
          placeholder={placeholder}
          onClick={e => { updateShowMenu(true) }}
        />
      </div>
      <div class="control">
        <span class={`dropdown is-right ${showMenu ? 'is-active' : ''}`}>
          <div class="dropdown-trigger">
          </div>
          <div class="dropdown-menu input_editor_dropdown"
            // style={{ width: `${1.9 * props.width}px` }}
            id="dropdown-menu3" role="menu">
            {showDropdown && <div class="dropdown-content">
              {message && <div class='dropdown-item'><span class='msg'>{message}</span></div>}
              {options && <div class='dropdown-item'><small>VALUES</small>
                <span className='close' onClick={e => updateShowMenu(false)}><i class='fas fa-times'></i></span>
              </div>}
              {options && options.map(o => <a
                class={`dropdown-item ${(dropdownItems[index] && (o.value === dropdownItems[index].value)) && 'is-active'}`}
                key={o.value}
                onClick={e => { handleOptionClick(o.value) }}>{o.label}
              </a>)}

              {fields && <div class='dropdown-item'><small>FIELDS</small>
                {!options && <span className='close' onClick={e => updateShowMenu(false)}><i class='fas fa-times'></i></span>}</div>}
              {fields && fields.map(o => <a
                class={`dropdown-item ${(dropdownItems[index] && (o.value === dropdownItems[index].value)) && 'is-active'}`}
                key={o.value}
                onClick={e => { handleOptionClick(o.value) }}>{o.label}
              </a>)}

            </div>}
          </div>
        </span>
      </div>
    </div>
  )
}
export default InputEditor