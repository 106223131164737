import React, { Component } from 'react';

class Slider extends Component {
  render() {
    let { value, onChange } = this.props.input;
    const { step, min, max, label } = this.props;
    return (
      <div className="slider field is-horizontal">
        <div className="field-label is-small">
          <label class="label is-small">{label}</label>
        </div>
        <div className="control"></div>
        <input
          class="slider is-fullwidth"
          type="range"
          step={step}
          min={min}
          max={max}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        ></input>
        <input
          class="slider-input input"
          type="number"
          step={step}
          min={min}
          max={max}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        ></input>
        {/* <output>{value}</output> */}
      </div>
    );
  }
}

export default Slider;
