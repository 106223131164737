import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setLeftPanel } from '../../../redux/actions';
import { getLeftPanel } from '../../../redux/selectors';
import './LeftSidebar.scss';

const PanelSelector = (props) => {
  const tab = useSelector(getLeftPanel)
  const dispatch = useDispatch()
  return (

    <div class="tabs is-small is-centered is-fullwidth">
      <ul>
        <li class={tab === 'fields' && `is-active`} onClick={e => { dispatch(setLeftPanel('fields')) }}>
          <a>
            <span class="icon is-small">
              <i class="far fa-object-ungroup"></i>
            </span>
            <span>Fields</span></a>
        </li>
        <li class={tab === 'pages' && `is-active`} onClick={e => { dispatch(setLeftPanel('pages')) }}>
          <a>
            <span className="icon is-small">
              <i className="far fa-file-alt"></i>
            </span>
            <span>Pages</span></a>
        </li>
      </ul>
    </div>
  )
}

export default PanelSelector;