import React from 'react';
import { connect } from 'react-redux';
import { setEditorMode } from 'redux/actions';
import { getEditorMode } from 'redux/selectors';

const EditorOptions = (props) => {
  const value = props.editorMode;
  const onChange = props.setEditorMode;

  function render() {
    return (
      <div class="field has-addons is-fullwidth">
        <p class="control">
          <button
            type="button"
            class={`button is-small ${value === 'editor' ? 'is-active' : ''}`}
            onClick={(e) => {
              onChange('editor');
            }}
          >
            <span class="icon is-small">
              <i class="far fa-edit"></i>
            </span>
            <span>Editor</span>
          </button>
        </p>

        <p class="control">
          <button
            type="button"
            class={`button is-small ${value === 'form' ? 'is-active' : ''}`}
            onClick={(e) => {
              onChange('form');
            }}
          >
            <span class="icon is-small">
              <i class="far fa-file-pdf"></i>
            </span>
            <span>Form</span>
          </button>
        </p>
      </div>
    );
  }
  return render();
};

const mapStateToProps = (state) => ({
  editorMode: getEditorMode(state)
});

export default connect(mapStateToProps, { setEditorMode })(EditorOptions);
