import useOnClickOutside from 'hooks/useOnClickOutside';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './Drawer.scss';
import { closeDrawer, openDrawerTab, registerDrawer } from './redux';

const Drawer = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(registerDrawer(props.id));
  }, [props.id]);

  const activeTab = useSelector((state) =>
    state.drawer[props.id] ? state.drawer[props.id].activeTab : ''
  );
  const isOpen = useSelector((state) =>
    state.drawer[props.id] ? state.drawer[props.id].open : false
  );

  const ref = useRef();
  useOnClickOutside(ref, () => {
    dispatch(closeDrawer(props.id));
  });

  const renderSidebar = () => {
    const tab = props.tabs.find((tab) => tab.name === activeTab);
    if (!tab) {
      return null;
    }
    const Component = tab.component;
    const compProps = tab.props;
    return (
      <aside className={`${isOpen ? '' : 'is-closed'}`}>
        <div className="header">
          <div className="level">
            <div className="level-left">
              <p className="has-text-weight-semibold">{activeTab}</p>
            </div>
            <div className="level-right">
              <i
                className="close fas fa-times"
                onClick={(e) => {
                  dispatch(closeDrawer(props.id));
                }}
              ></i>
            </div>
          </div>
        </div>
        <div className="component">
          <Component {...compProps} />
        </div>
      </aside>
    );
  };

  const renderTabs = (tab) => {
    return (
      <div
        className={`tab ${
          tab.name === activeTab && isOpen ? 'active-tab' : ''
        }`}
      >
        <i
          class={tab.icon}
          onClick={(e) => {
            handleTabClick(tab.name);
          }}
        ></i>
      </div>
    );
  };

  const handleTabClick = (tab) => {
    if (activeTab === tab) {
      dispatch(closeDrawer(props.id));
    } else {
      dispatch(openDrawerTab(props.id, tab));
    }
  };

  return (
    <div className={`drawer ${isOpen ? '' : 'is-closed'}`} ref={ref}>
      <div className="drawer_tabs">
        {props.tabs.map((tab) => renderTabs(tab))}
      </div>
      {isOpen ? renderSidebar() : null}
    </div>
  );
};

export default Drawer;
