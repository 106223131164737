import server from 'api/server';
import Page from 'components/Page';
import React, { useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import './App.scss';
import CustomEmailSettings from './CustomEmail/Settings';

const Settings = (props) => {
  const appId = props.match.params.id;
  const [app, setApp] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchApp = async (appId) => {
      const { data } = await server.get(`/apps/app/${appId}`);
      setApp(data);
      setLoading(false);
    };
    try {
      fetchApp(appId);
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <Page title="Integration Settings">
      <div className="page app_settings_page settings_page">
        <div class="container">
          <div className="box page-box">
            {loading ? (
              <div className="loader_container">
                <Loader type="Oval" color="#ccc" height={30} width={30} />
              </div>
            ) : (
              <CustomEmailSettings data={app} />
            )}
          </div>
        </div>
      </div>
    </Page>
  );
};
export default Settings;
