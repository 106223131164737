import React, { Component } from 'react';

class VerticalAlignment extends Component {
  render() {
    let { value, onChange } = this.props.input;
    return (
      <div className="field is-horizontal">
        <div class="field-label is-small">
          <label class="label">{'  '}</label>
        </div>
        <div class="field-body">
          <div class="field has-addons is-fullwidth">
            <p class="control">
              <button
                type="button"
                class={`button is-small ${value === 'top' ? 'is-active' : ''}`}
                onClick={(e) => {
                  onChange('top');
                }}
              >
                <span class="icon is-small">
                  <i class="fas fa-sort-up"></i>
                </span>
              </button>
            </p>
            <p class="control">
              <button
                type="button"
                class={`button is-small ${
                  value === 'center' ? 'is-active' : ''
                }`}
                onClick={(e) => {
                  onChange('center');
                }}
              >
                <span class="icon is-small">
                  <i class="fas fa-sort"></i>
                </span>
              </button>
            </p>
            <p class="control">
              <button
                type="button"
                class={`button is-small ${
                  value === 'bottom' ? 'is-active' : ''
                }`}
                onClick={(e) => {
                  onChange('bottom');
                }}
              >
                <span class="icon is-small">
                  <i class="fas fa-sort-down"></i>
                </span>
              </button>
            </p>
          </div>
        </div>
      </div>
    );
  }
}
export default VerticalAlignment;
