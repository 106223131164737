import SignatureField from 'components/_common/FormFields/SignatureField';
import React, { useState } from 'react';
import { validateEmail } from 'utils/validators';
import './SignaturePrompt.scss';

const Modal = ({ closeModal, modalState, title, onSave, tabs, focus }) => {
  const [value, setValue] = useState();
  const [valid, setValid] = useState(false);
  const [error, setError] = useState(null);
  if (!modalState) {
    return null;
  }
  const validate = () => {
    if (value) {
      if (!value.signerName || !value.signerEmail || !value.signature) {
        setValid(false);
        setError([
          'Please provide the full legal name, email address and signature!'
        ]);
      } else {
        const validEmail = validateEmail(value.signerEmail);
        if (validEmail) {
          setValid(true);
          setError(null);
        } else {
          setError([
            ...(typeof error === 'array' ? error : []),
            'Email address is not valid'
          ]);
        }
      }
    }
  };

  return (
    <div
      className="modal modal-signature is-active"
      style={{
        opacity: 1,
        transition: 'opacity 300ms ease-in-out',
        zIndex: '99999999999'
      }}
    >
      <div className="modal-background" onClick={closeModal} />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">{title}</p>
          <button className="delete" onClick={closeModal} />
        </header>
        <section className="modal-card-body">
          <div className="content">
            <SignatureField
              input={{
                value: null,
                onChange: (val) => {
                  setValue(val);
                  validate();
                }
              }}
              authFields="nameEmail"
              tabs={tabs}
              meta={{
                touched: true,
                error
              }}
              focus={focus}
            />
          </div>
        </section>
        <footer className="modal-card-foot">
          <a
            class="button is-info"
            onClick={() => {
              validate();
              if (valid) {
                onSave(value);
                closeModal();
              }
            }}
          >
            Save
          </a>
          <a
            className="button"
            onClick={() => {
              setValue();
              setError();
              setValid(false);
              closeModal();
            }}
          >
            Cancel
          </a>
        </footer>
      </div>
    </div>
  );
};

const SignaturePrompt = (props) => {
  const { value, onChange } = props.input;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const toggleModal = () => setIsOpen(!!!modalIsOpen);
  return (
    <div class="signaturePrompt">
      <Modal
        closeModal={toggleModal}
        modalState={modalIsOpen}
        tabs={props.tabs}
        onSave={(sig) => onChange(sig)}
        title="Add Signature"
        focus={props.focus}
      />
      <div className="signature_field">
        <div class="field">
          <label class="label is-small">Signature</label>
          {value && (
            <>
              <div style={{ border: '1px solid #dadada', marginBottom: '5px' }}>
                <img
                  src={typeof value === 'string' ? value : value.signature}
                  style={{ padding: '10px' }}
                />
              </div>
              <button
                type="button"
                className=" button is-small is-fullwidth"
                onClick={(e) => {
                  onChange(null);
                }}
              >
                Clear Signature
              </button>
            </>
          )}
          {!value && (
            <>
              <button
                type="button"
                className=" button is-small is-fullwidth"
                onClick={() => setIsOpen(true)}
              >
                Add Signature
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default SignaturePrompt;
