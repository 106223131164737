import React, { useEffect, useState } from 'react';

const Checkbox = (props) => {
  const [status, setStatus] = useState(props.input.value);
  useEffect(() => {
    props.input.onChange(props.evaluatedValue);
  }, [props.evaluatedValue]);

  function render() {
    const {
      input,
      label,
      meta: { touched, error, warning }
    } = props;
    let { onChange } = input;
    return (
      <div className="field" style={props.hidden ? { display: 'none' } : {}}>
        <div className="control">
          <input
            className={`is-checkradio is-info  ${
              touched && error ? 'is-danger' : ''
            }`}
            type="checkbox"
            id={label}
            onChange={(e) => {
              setStatus(e.target.checked);
              onChange(e.target.checked);
              if (props.track) props.track(e.target.checked);
            }}
            checked={props.evaluatedValue || status}
            onFocus={props.focus}
          />
          <label for={label}>{label}</label>
        </div>
        {props.description && <p className="help">{props.description}</p>}
        <p className="help is-danger">
          {touched &&
            ((error && <span>{error}</span>) ||
              (warning && <span>{warning}</span>))}
          {props.warning
            ? props.warnings.map((warning, i) => <span>{warning}</span>)
            : null}
        </p>
      </div>
    );
  }
  return render();
};

export default Checkbox;
