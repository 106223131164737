/* eslint-disable array-callback-return */
import Checkbox from 'components/_common/FormFields/Checkbox';
import DateTimePicker from 'components/_common/FormFields/DateTimePicker';
import Dropdown from 'components/_common/FormFields/Dropdown';
import ImageField from 'components/_common/FormFields/ImageField';
import SignatureField from 'components/_common/FormFields/SignatureField';
import TextArea from 'components/_common/FormFields/TextArea';
import TextField from 'components/_common/FormFields/TextField';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTracking } from 'react-tracking';
import {
  Field,
  getFormSyncErrors,
  getFormValues,
  reduxForm,
  SubmissionError,
  updateSyncErrors
} from 'redux-form';
import {
  email,
  length,
  numericality,
  required,
  url
} from 'redux-form-validators';
import {
  evaluateConditionalLogic,
  evaluateExpression
} from 'utils/conditional';
import { validateSignature } from 'utils/validators';

const Form = (props) => {
  const [isValidating, setValidating] = useState(false);
  const { trackEvent } = useTracking({});
  const dispatch = useDispatch();
  useEffect(() => {
    const { setTrackingData } = props;
    if (setTrackingData) setTrackingData(window.dataLayer);
  }, [window.dataLayer]);

  useEffect(() => {
    let defaults = {};
    props.fields.map((field) => {
      if (['text', 'number'].includes(field.data.type)) {
        defaults[field.data.name] = field.data.default;
      }
    });
    props.initialize(defaults);
    if (props.initialValues) {
      props.initialize(props.initialValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.fields]);

  let fieldCurrentValues = useSelector((state) =>
    getFormValues(props.form)(state)
  );

  fieldCurrentValues = fieldCurrentValues || {};

  const getValidationRules = (field, context) => {
    const { min, max, minLength, maxLength, type, dataType } = field.data;
    let rules = [];

    if (field.data.required) {
      rules.push(required());
    }
    if (type && type === 'number') {
      if (context[field.data.name]) {
        rules.push(numericality());
      }
    }
    if (minLength) {
      if (context[field.data.name]) {
        rules.push(length({ min: parseInt(minLength) }));
      }
    }
    if (maxLength) {
      if (context[field.data.name]) {
        rules.push(length({ max: parseInt(maxLength) }));
      }
    }
    if (min) {
      if (context[field.data.name]) {
        rules.push(numericality({ '>=': parseFloat(min) }));
      }
    }
    if (max) {
      if (context[field.data.name]) {
        rules.push(numericality({ '<=': parseFloat(max) }));
      }
    }
    if (context[field.data.name]) {
      switch (dataType) {
        case 'email': {
          rules.push(email());
          return rules;
        }
        case 'url': {
          rules.push(url());
          return rules;
        }
        case 'int': {
          rules.push(numericality({ int: true }));
          return rules;
        }
        default:
          return rules;
      }
    }
    return rules;
  };

  function renderNumberField(field, index, disabled) {
    const context = fieldCurrentValues;

    let visible = true;
    let expressionValue;

    if (field.data.default)
      expressionValue = evaluateExpression(field.data.default, context);
    if (field.data.conditionalFormula)
      [visible, expressionValue] = evaluateConditionalLogic(
        field.data.conditionalFormula,
        context
      );

    return visible ? (
      <Field
        label={field.data.label ? field.data.label : field.data.name}
        component={TextField}
        required={field.data.hiddenForm ? false : field.data.required}
        hidden={field.data.hiddenForm || false}
        static={field.data.static}
        name={field.data.name}
        description={field.data.description}
        type="number"
        evaluatedValue={expressionValue} // conditional formula
        key={index}
        validate={!field.data.default && getValidationRules(field, context)}
        disabled={disabled}
        requiredLabel={props.labels && props.labels.requiredLabel}
        focus={() => props.setActiveField(field.data.id)}
        track={(value) =>
          trackEvent({
            field: field.data.name,
            type: 'number',
            updated: new Date(),
            value
          })
        }
      />
    ) : null;
  }

  function renderCheckboxField(field, index, disabled) {
    const context = fieldCurrentValues;
    let visible = true;
    let expressionValue;
    if (field.data.default)
      expressionValue = evaluateExpression(field.data.default, context);
    if (field.data.conditionalFormula)
      [visible, expressionValue] = evaluateConditionalLogic(
        field.data.conditionalFormula,
        context
      );

    return visible ? (
      <Field
        label={field.data.label ? field.data.label : field.data.name}
        component={Checkbox}
        hidden={field.data.hiddenForm || false}
        static={field.data.static}
        required={field.data.hiddenForm ? false : field.data.required}
        name={field.data.name}
        description={field.data.description}
        evaluatedValue={expressionValue}
        key={index}
        validate={getValidationRules(field, fieldCurrentValues)}
        disabled={disabled}
        requiredLabel={props.labels && props.labels.requiredLabel}
        focus={() => props.setActiveField(field.data.id)}
        track={(value) =>
          trackEvent({
            field: field.data.name,
            type: 'checkbox',
            updated: new Date(),
            value
          })
        }
      />
    ) : null;
  }

  function renderDateTimeField(field, index, disabled) {
    const context = fieldCurrentValues;
    let visible = true;
    let expressionValue;

    if (field.data.default)
      expressionValue = evaluateExpression(field.data.default, context);
    if (field.data.conditionalFormula)
      [visible, expressionValue] = evaluateConditionalLogic(
        field.data.conditionalFormula,
        context
      );
    let format =
      field.data.dateTimeFormat === 'custom'
        ? field.data.customFormat
        : field.data.dateTimeFormat;

    return visible ? (
      <Field
        dateTimeFormat={format}
        label={field.data.label ? field.data.label : field.data.name}
        component={DateTimePicker}
        required={field.data.hiddenForm ? false : field.data.required}
        hidden={field.data.hiddenForm || false}
        static={field.data.static}
        name={field.data.name}
        evaluatedValue={expressionValue}
        description={field.data.description}
        type="text"
        key={index}
        disabled={disabled}
        requiredLabel={props.labels && props.labels.requiredLabel}
        focus={() => props.setActiveField(field.data.id)}
        track={(value) =>
          trackEvent({
            field: field.data.name,
            type: 'dateTime',
            updated: new Date(),
            value
          })
        }
      />
    ) : null;
  }

  const renderTextField = (field, index, disabled) => {
    const context = fieldCurrentValues;
    let visible = true;
    let expressionValue;

    if (field.data.default)
      expressionValue = evaluateExpression(field.data.default, context);
    if (field.data.conditionalFormula)
      [visible, expressionValue] = evaluateConditionalLogic(
        field.data.conditionalFormula,
        context
      );

    let typeProps = { component: TextField, type: 'text', options: [] };
    switch (field.data.dataType) {
      case 'multiple': {
        typeProps.component = Dropdown;
        typeProps.options = field.data.fieldOptions;
        break;
      }
      case 'paragraph': {
        typeProps.component = TextArea;
        break;
      }
      default:
        break;
    }
    return visible ? (
      <Field
        label={field.data.label ? field.data.label : field.data.name}
        {...typeProps}
        name={field.data.name}
        required={field.data.hiddenForm ? false : field.data.required}
        description={field.data.description}
        hidden={field.data.hiddenForm || false}
        static={field.data.static}
        key={index}
        evaluatedValue={expressionValue}
        validate={getValidationRules(field, fieldCurrentValues)}
        disabled={disabled}
        placeholder=""
        requiredLabel={props.labels && props.labels.requiredLabel}
        focus={() => props.setActiveField(field.data.id)}
        track={(value) =>
          trackEvent({
            field: field.data.name,
            type: field.data.dataType,
            updated: new Date(),
            value
          })
        }
      />
    ) : null;
  };

  const renderSignatureField = (field, index, disabled) => {
    const context = fieldCurrentValues;
    let visible = true;
    let expressionValue;

    if (field.data.default)
      expressionValue = evaluateExpression(field.data.default, context);
    if (field.data.conditionalFormula)
      [visible, expressionValue] = evaluateConditionalLogic(
        field.data.conditionalFormula,
        context
      );

    if (!disabled) {
      if (field.data.source === 'user') {
        return (
          visible && (
            <div className="field signature_field">
              <Field
                label={field.data.label ? field.data.label : field.data.name}
                hidden={field.data.hiddenForm || false}
                static={field.data.static}
                required={true}
                validate={[
                  ...getValidationRules(field, fieldCurrentValues),
                  validateSignature
                ]}
                component={SignatureField}
                tabs={field.data.tabs}
                authFields={field.data.authFields || 'nameEmail'}
                description={field.data.description}
                name={field.data.name}
                requiredLabel={props.labels && props.labels.requiredLabel}
                language={field.data.language}
                focus={() => props.setActiveField(field.data.id)}
                track={(value) =>
                  trackEvent({
                    field: field.data.name,
                    type: 'signature',
                    updated: new Date(),
                    value
                  })
                }
              />
            </div>
          )
        );
      }
    } else {
      return null;
    }
  };

  const renderImageField = (field, index, disabled) => {
    const context = fieldCurrentValues;
    let visible = true;
    let expressionValue;

    if (field.data.default)
      expressionValue = evaluateExpression(field.data.default, context);
    if (field.data.conditionalFormula)
      [visible, expressionValue] = evaluateConditionalLogic(
        field.data.conditionalFormula,
        context
      );

    if (!disabled) {
      return (
        visible && (
          <div className="field signature_field">
            <Field
              label={field.data.label ? field.data.label : field.data.name}
              hidden={field.data.hiddenForm || false}
              static={field.data.static}
              required={field.data.hiddenForm ? false : field.data.required}
              type={props.mode === 'preview' ? 'base64' : 'url'}
              validate={getValidationRules(field, fieldCurrentValues)}
              component={ImageField}
              description={field.data.description}
              name={field.data.name}
              requiredLabel={props.labels && props.labels.requiredLabel}
              focus={() => props.setActiveField(field.data.id)}
              track={(value) =>
                trackEvent({
                  field: field.data.name,
                  type: 'image',
                  updated: new Date(),
                  value
                })
              }
            />
          </div>
        )
      );
    } else {
      return null;
    }
  };

  const renderGroupField = (field, index, disabled) => {
    // Get the group fields.
    const groupFieldsIds =
      field.data.fields && field.data.fields.length > 0
        ? field.data.fields.map((f) => f.value)
        : [];
    // Get the original order of the fields.
    const groupFields = [];
    props.fields.forEach((f) => {
      if (groupFieldsIds.includes(f.data.id)) {
        groupFields.push(f);
      }
    });
    // Render group label and description and the fields
    return (
      <div className="field group_field" key={index}>
        <label className="label ">
          {field.data.label ? field.data.label : field.data.name}{' '}
        </label>

        {field.data.description && (
          <p className="help description">{field.data.description}</p>
        )}
        <hr class="label_hr" />

        <div class="fields">
          {groupFields.map((f, i) => renderField(f, i, disabled))}
        </div>
        <hr class="fields_hr" />
      </div>
    );
  };

  const renderField = (field, index, disabled = false) => {
    switch (field.data.type) {
      case 'text':
        return renderTextField(field, index, disabled);
      case 'number':
        return renderNumberField(field, index, disabled);
      case 'signature':
        return renderSignatureField(field, index, disabled);
      case 'checkbox':
        return renderCheckboxField(field, index, disabled);
      case 'dateTime':
        return renderDateTimeField(field, index, disabled);
      case 'image':
        return renderImageField(field, index, disabled);
      case 'group':
        return renderGroupField(field, index, disabled);
      default:
        return null;
    }
  };

  let disabledFields = [];
  if (props.initialValues) {
    disabledFields = Object.keys(props.initialValues);
  }

  const onSubmit = (values, dispatch) => {
    setValidating(true);
    const errors = {};
    props.fields.forEach((f) => {
      if (
        f.data.type === 'checkbox' &&
        f.data.required &&
        !values[f.data.name]
      ) {
        errors[f.data.name] = 'is required';
      }
      // else if (f.data.type === 'signature') {
      //   const message = validateSignature(values[f.data.name]);
      //   if (message) errors[f.data.name] = message;
      // }
    });
    if (Object.keys(errors).length > 0) {
      dispatch(updateSyncErrors('triggerDocumentForm', errors));
      setValidating(false);
      return new SubmissionError(errors);
    } else {
      setValidating(false);
      props.onSubmit(values);
    }
  };
  const renderForm = () => {
    const { handleSubmit, submitting, pristine } = props;

    // Get the ids of the fields that belong to group fields.
    const groupFieldIds = [];
    props.fields
      .filter((f) => f.data.type === 'group')
      .forEach((f) => {
        if (f.data.fields && f.data.fields.length > 0) {
          f.data.fields.map((f) => {
            groupFieldIds.push(f.value);
          });
        }
      });

    return (
      <form
        name="trigger_document_form"
        className="form_fields"
        onSubmit={handleSubmit(onSubmit)}
      >
        {props.fields.map((field, index) => {
          // If the field belongs to a group field, skip it.
          if (groupFieldIds.includes(field.data.id)) {
            return null;
          }

          let disabled = false;
          if (disabledFields.includes(field.data.name)) disabled = true;

          if (props.displayFields && props.displayFields.value === 'selected') {
            const showFields = Array.isArray(props.selectedFields)
              ? props.selectedFields.map((f) => f.value)
              : [];
            if (showFields.includes(field.data.name)) {
              return renderField(field, index, disabled);
            } else return null;
          } else {
            return renderField(field, index, disabled);
          }
        })}
        <br />
        {props.errors && <p className="help is-danger">{props.errors}</p>}
        <button
          className={`button is-small is-info ${submitting || props.isSubmitting ? 'is-loading' : ''
            }`}
          disabled={props.disableSubmit || props.isSubmitting}
          type="submit"
        >
          {props.labels && props.labels.submitLabel
            ? props.labels.submitLabel
            : 'Submit'}
        </button>
      </form>
    );
  };
  return renderForm();
};
const wrappedForm = reduxForm({ form: 'triggerDocumentForm' })(Form);

export default wrappedForm;
