/* eslint-disable jsx-a11y/anchor-is-valid */
import server from 'api/server';
import * as moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getUser, isLoggedIn } from 'redux/selectors';
import './MessageBar.scss';

const EmailActivationMessage = (props) => {
  const [removeBar, setRemoveBar] = useState(false);
  const [resend, setResend] = useState(false);

  const user = useSelector(getUser);
  const isUserLoggedIn = useSelector(isLoggedIn);

  const resendEmail = async () => {
    try {
      setResend(true);
      await server.post('/auth/confirm/resend');
    } catch (error) {
      setResend(false);
      console.log(error.response.data);
    }
  };
  const renderButton = () => {
    return (
      <button
        onClick={(e) => setRemoveBar(true)}
        class="delete is-pulled-right"
        aria-label="delete"
      ></button>
    );
  };

  let showBar = !user.emailConfirmed || false;
  if (isUserLoggedIn) {
    showBar = showBar && !removeBar;
  } else {
    showBar = false;
  }
  return (
    showBar && (
      <nav class="message is-info">
        <div class="message-body">
          <div className="container">
            {!resend && (
              <p class="">
                Please check your email for a link to confirm your email address
                <span className="user_email"> ({user.email}). </span>
                <a onClick={resendEmail} className="resend_link">
                  Resend email
                </a>{' '}
                or <Link to="/settings">Change your email address</Link>
                {renderButton()}
              </p>
            )}
            {resend && (
              <p>
                Confirmation email has been resent to your email{' '}
                <span className="user_email">({user.email}).</span>
                {renderButton()}
              </p>
            )}
          </div>
        </div>
      </nav>
    )
  );
};

const WelcomeMessage = (props) => {
  const [removeBar, setRemoveBar] = useState(false);
  const user = useSelector(getUser);
  const isUserLoggedIn = useSelector(isLoggedIn);

  const renderButton = () => {
    return (
      <button
        onClick={(e) => setRemoveBar(true)}
        class="delete is-pulled-right"
        aria-label="delete"
      ></button>
    );
  };

  let showBar = !user.emailConfirmed || false;
  if (isUserLoggedIn) {
    showBar = showBar && !removeBar;
  } else {
    showBar = false;
  }

  return showBar && user && user.welcome ? (
    <nav class="message is-success">
      <div class="message-body">
        <div className="container">
          <p class="">
            {user.welcome}
            {renderButton()}
          </p>
        </div>
      </div>
    </nav>
  ) : null;
};

const LimitExceededMessage = (props) => {
  const [removeBar, setRemoveBar] = useState(false);
  const user = useSelector(getUser);
  const isUserLoggedIn = useSelector(isLoggedIn);
  const { plan } = user.subscription || {};
  const { usageData } = user.usage || {};
  const { currentWindowEnd } = usageData || {};
  const renderButton = () => {
    return (
      <button
        onClick={(e) => setRemoveBar(true)}
        class="delete is-pulled-right"
        aria-label="delete"
      ></button>
    );
  };
  let showBar = false;
  if (usageData) showBar = usageData.overage.generated_documents || false;
  if (isUserLoggedIn) {
    showBar = showBar && !removeBar;
  } else {
    showBar = false;
  }
  return (
    showBar && (
      <nav class="message is-danger">
        <div class="message-body">
          <div className="container">
            <p class="">
              You have exceeded usage limit of <strong>{plan}</strong> plan.
              <span>
                {' '}
                <Link to="/settings/billing">Upgrade your plan</Link>
              </span>{' '}
              to conitnue or wait till{' '}
              <strong>{moment(currentWindowEnd).format('MMMM Do YYYY')}</strong>
              {'.'}
              {renderButton()}
            </p>
          </div>
        </div>
      </nav>
    )
  );
};

const MessageBar = (props) => {
  return (
    <div className="messageBar">
      <WelcomeMessage />
      <EmailActivationMessage />
      <LimitExceededMessage />
    </div>
  );
};
export default MessageBar;
