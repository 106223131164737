import React, { Component } from 'react';

class DeleteField extends Component {
  render() {
    return (
      <div class="field delete_button">
        <button
          onClick={(e) => {
            e.preventDefault();
            if (window.confirm(`Are you sure you want to delete this field?`)) {
              this.props.onDelete(e);
            }
          }}
          className="button is-danger is-small is-fullwidth is-outlined"
        >
          <span class="icon is-small">
            <i class="fa fa-trash"></i>
          </span>
          <span>Delete Field</span>
        </button>
      </div>
    );
  }
}
export default DeleteField;
