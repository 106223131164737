import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import uniqid from 'uniqid';
import './Conditional.scss';
import Logic from './Logic';

const Conditional = (props) => {
  //TODO: can use custom components for the buttons
  //API: https://www.npmjs.com/package/react-querybuilder#controlelements-optional

  const [logics, setLogics] = useState(props.input.value || []);

  useEffect(() => {
    props.input.onChange(logics);
  }, [logics]);

  useEffect(() => {
    setLogics(props.input.value);
    props.input.onChange(logics);
  }, [props.input.value]);

  const getFieldsNameAndLabel = () => {
    let condFields = [];
    props.fields.forEach((field) => {
      if (field.data) {
        condFields.push({
          name: field.data.name,
          label: field.data.name,
          data: field.data
        });
      }
    });
    return condFields;
  };

  const getOperators = (f) => {
    const defaultCase = [
      { name: 'null', label: 'is empty' },
      { name: 'notNull', label: 'is not empty' }
    ];

    const field = getFieldsNameAndLabel().find((i) => i.name === f);
    let type;
    if (field) {
      if (field.data && field.data.type) type = field.data.type;
    } else return defaultCase;

    if (!type) return defaultCase;

    switch (type) {
      case 'text':
        return [
          { name: 'null', label: 'is empty' },
          { name: 'notNull', label: 'is not empty' },
          { name: '=', label: 'is equal to' },
          { name: 'contains', label: 'contains' },
          { name: 'beginsWith', label: 'begins with' },
          { name: 'endsWith', label: 'ends with' },
          { name: 'doesNotContain', label: 'does not contain' },
          { name: 'doesNotBeginWith', label: 'does not begin with' },
          { name: 'doesNotEndWith', label: 'does not end with' }
        ];
      case 'number':
        return [
          { name: 'null', label: 'is empty' },
          { name: 'notNull', label: 'is not empty' },
          { name: '=', label: 'is equal to' },
          { name: '!=', label: 'is not equal to' },
          { name: '<', label: 'is less than' },
          { name: '>', label: 'is greater than' },
          { name: '<=', label: 'is equal or less than' },
          { name: '>=', label: 'is equal or greater than' }
        ];
      case 'checkbox':
        return [
          { name: '=', label: 'is equal to' },
          { name: '!=', label: 'is not equal to' }
        ];
      case 'dateTime':
        return [
          { name: 'null', label: 'is empty' },
          { name: 'notNull', label: 'is not empty' },
          { name: 'isSame', label: 'is same day as' },
          { name: 'isBefore', label: 'is before' },
          { name: 'isAfter', label: 'is after' }
        ];
      default:
        return [
          { name: 'null', label: 'is empty' },
          { name: 'notNull', label: 'is not empty' }
        ];
    }
  };

  const addLogic = (type) => {
    let newLogics = [...logics];
    newLogics = [
      ...newLogics,
      { id: uniqid(), query: {}, expression: '', type: 'show' }
    ];
    setLogics(newLogics);
    props.input.onChange(newLogics);
  };
  const deleteLogic = (index, formula) => {
    let newLogics = [...logics];
    newLogics = newLogics.filter((element) => element.id !== formula.id);
    setLogics(newLogics);
    props.input.onChange(newLogics);
  };
  const updateQuery = (index, query) => {
    let newLogics = [...logics];
    newLogics[index].query = query;
    setLogics(newLogics);
    props.input.onChange(newLogics);
  };
  const updateType = (index, type) => {
    let newLogics = [...logics];
    newLogics[index].type = type;
    setLogics(newLogics);
    props.input.onChange(newLogics);
  };
  const updateExpression = (index, expression) => {
    let newLogics = [...logics];
    newLogics[index].expression = expression;
    setLogics(newLogics);
    props.input.onChange(newLogics);
  };

  const render = () => {
    const fields = getFieldsNameAndLabel(props.fields);

    return (
      <div className="field conditional_formula">
        {logics.map((formula, index) => {
          return (
            <Logic
              formula={formula}
              index={index}
              className={index + 1 === logics.length ? 'last_logic' : ''}
              key={formula.id}
              onTypeChange={updateType}
              onQueryChange={updateQuery}
              onExpressionChange={updateExpression}
              onDelete={deleteLogic}
              getOperators={getOperators}
              field={props.field}
              fields={fields}
              tooltip={props.tooltip}
            />
          );
        })}
        <button
          className="button add_button is-fullwidth is-small is-centered"
          type="button"
          onClick={addLogic}
        >
          <span className="text">Add Conditional Logic</span>
          <span class="icon is-small">
            <i class="fas fa-plus-circle"></i>
          </span>
        </button>
        <ReactTooltip
          className="field_tooltip"
          place="left"
          effect="solid"
          offset={{ top: 0 }}
          multiline={true}
        />
      </div>
    );
  };
  return render();
};
export default Conditional;
