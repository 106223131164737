import DownloadButton from 'components/_common/DownloadButton';
import useOnClickOutside from 'hooks/useOnClickOutside';
import React, { useRef, useState } from 'react';

import server from 'api/server';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import './DropdownButton.scss';

const DropdownItem = (props) => {
  const [loading, setLoading] = useState(false);
  return (
    <a
      class={`dropdown-item`}
      onClick={async () => {
        if (props.confirm) {
          if (window.confirm(props.confirm)) {
            setLoading(true);
            await props.onClick();
            setLoading(false);
          }
        } else {
          setLoading(true);
          await props.onClick();
          setLoading(false);
        }
      }}
    >
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <span class="icon is-small">
            <i class="fas fa-circle-notch fa-spin" />
          </span>
        </div>
      ) : (
        props.children
      )}
    </a>
  );
};

const DropdownButton = ({ data }) => {
  const { path: url, type, fileName, id, status, workflowRunId } = data;
  const [dropdown, setDropdown] = useState(false);
  const ref = useRef();
  useOnClickOutside(ref, () => {
    setDropdown(false);
  });
  const toggle = () => setDropdown(!dropdown);

  const cancelWorkflow = async () => {
    try {
      await server.post('/cancel/workflow', { workflowRunId });
      await new Promise((r) => setTimeout(r, 2000));
      toast.info('Status updated to canceled');
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };
  const sendWaitingEmail = async () => {
    try {
      await server.post('/email/intermediate', { generatedDocId: id });
      toast.info('Reminder email sent');
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div class="dropdown-with-button">
      <div class="main-button">
        <DownloadButton
          url={url}
          extension={type === 'PDF' ? 'pdf' : ''}
          fileName={fileName}
          disabled={status !== 'COMPLETED'}
          classes="button is-small is-uppercase has-text-weight-semibold is-fullwidth"
        />
      </div>
      <div class={`dropdown is-right ${dropdown ? 'is-active' : ''}`} ref={ref}>
        <div class="dropdown-trigger">
          <button
            class="button is-small"
            aria-haspopup="true"
            aria-controls="dropdown-menu"
            onClick={() => toggle()}
          >
            <span class="icon is-small">
              <i class="fas fa-angle-down" aria-hidden="true"></i>
            </span>
          </button>
        </div>
        <div class="dropdown-menu" id="dropdown-menu" role="menu">
          <div class="dropdown-content">
            <Link to={`/history/document/${id}`} class="dropdown-item">
              View Details
            </Link>
            {status === 'WAITING' && (
              <DropdownItem onClick={sendWaitingEmail}>
                Send Reminder
              </DropdownItem>
            )}

            {status === 'WAITING' && (
              <>
                <hr />
                <DropdownItem
                  onClick={cancelWorkflow}
                  confirm="Are your sure you want to cancel the document?"
                >
                  Cancel
                </DropdownItem>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default DropdownButton;
