/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { WithContext as ReactTags } from 'react-tag-input';
import ReactTooltip from 'react-tooltip';
// "react-dnd": "^5.0.0",   
//  "react-dnd-html5-backend": "^3.0.2",


const TagsInput = props => {
  let { value, onChange } = props.input;
  const [tags, setTags] = useState(value || [])


  const onDelete = i => {
    let t = tags.filter((t, idx) => idx !== i);
    setTags(t);
    onChange(t)
  }
  const onAdd = tag => {
    let t = [...tags, tag];
    setTags(t);
    onChange(t)
  }
  useEffect(() => { onChange(tags) }, [tags])

  return (
    <div className='field tags_input is-horizontal'>
      <div class="field-label is-small">
        <label for={props.label} class="label">{props.label}</label>
      </div>
      <div class="field-body">

        <ReactTags tags={tags}
          classNames={{
            tags: 'tags',
            tag: 'tag',
            remove: 'removeClass',
          }}
          allowDragDrop={false}
          inline={true}
          placeholder={props.placeholder || 'Press enter to add'}
          handleDelete={onDelete}
          handleAddition={onAdd}
          delimiters={[188, 13]} />
        {props.tooltip && <i class="fas fa-question-circle" data-tip={props.tooltip}></i>}
      </div>
      <ReactTooltip className='field_tooltip' place='left' effect='solid' offset={{ top: 0 }} multiline={true} />
    </div>
  )
}

export default TagsInput;