import React from 'react';
import { toast } from 'react-toastify';

const CopyButton = ({ text }) => {
  return (
    <svg
      class="svg-icon hover copy_button"
      height="27"
      width="26"
      viewBox="0 0 26 27"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      icon="copy"
      onClick={() => {
        navigator.clipboard.writeText(text);
        toast.info('Copied to clipboard!');
      }}
      style={{ cursor: 'pointer' }}
    >
      <defs>
        <circle
          id="a"
          cx="12"
          cy="12"
          r="12"
          fill="#fff"
          stroke="#fff"
        ></circle>
        <filter id="b" height="120.8%" width="116.7%" x="-8.3%" y="-8.3%">
          <feOffset
            dy="1"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation=".5"
          ></feGaussianBlur>
          <feColorMatrix
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
            values="0 0 0 0 0.0392156863 0 0 0 0 0.0862745098 0 0 0 0 0.274509804 0 0 0 0.1 0"
          ></feColorMatrix>
          <feOffset in="SourceAlpha" result="shadowOffsetOuter2"></feOffset>
          <feGaussianBlur
            in="shadowOffsetOuter2"
            result="shadowBlurOuter2"
            stdDeviation=".5"
          ></feGaussianBlur>
          <feColorMatrix
            in="shadowBlurOuter2"
            result="shadowMatrixOuter2"
            values="0 0 0 0 0.0392156863 0 0 0 0 0.0862745098 0 0 0 0 0.274509804 0 0 0 0.06 0"
          ></feColorMatrix>
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
            <feMergeNode in="shadowMatrixOuter2"></feMergeNode>
          </feMerge>
        </filter>
      </defs>
      <g fill="none" fill-rule="evenodd">
        <g transform="translate(1 1)">
          <use filter="url(#b)" href="#a"></use>
          <use fill="#8a94a6" href="#a"></use>
        </g>
        <g stroke="#8a94a6" stroke-linecap="round" stroke-linejoin="round">
          <path d="m8.284 10.716h7v7h-7z"></path>
          <path d="m11.33 8.284h6.25v7.386"></path>
        </g>
      </g>
    </svg>
  );
};
export default CopyButton;
