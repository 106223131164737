import useDimensions from 'hooks/useDimensions';
import React, { useEffect, useState } from 'react';
import EditableLabel from 'react-inline-editing';
import { useDispatch, useSelector } from 'react-redux';
import { Field, isValid, reduxForm } from 'redux-form';
import { required } from 'redux-form-validators';
import { fetchEmailApps } from 'redux/actions/appActions';
import { saveStepToServer, updateStep } from 'redux/actions/workflowActions';
import { getEmailApps, isActionPending } from 'redux/selectors';
import { commaSeparatedEmails } from 'utils/validators';
import { FieldSelect, ReSelect } from '../components/Select';
import TinyEditor from '../components/TinyEditor';
import useExposedFields from './useExposedFields';

const EmailSettingsComponent = (props) => {
  const step = props.step;
  const dispatch = useDispatch();
  const valid = useSelector((state) => isValid(props.form)(state));
  const prevData = useExposedFields(step.order);
  const emails = useSelector(getEmailApps);
  const emailOptions = [];
  emails.forEach((e) => {
    if (e.status === 'active') {
      emailOptions.push({ label: e.name, value: e.id, type: e.type });
    }
  });
  emailOptions.push({
    label: 'notifications@docdown.io',
    value: 'notifications@docdown.io',
    type: 'default'
  });
  const pendingUpdate = useSelector((state) =>
    isActionPending(state, 'UPDATE_STEP')
  );

  const [ref, { x }] = useDimensions(true);

  useEffect(() => {
    props.initialize(props.initialValues);
    dispatch(fetchEmailApps());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function submit(values) {
    const {
      fromEmail,
      toEmail,
      cc,
      bcc,
      subject,
      message,
      attachment
    } = values;
    const newStep = {
      ...step,
      name,
      data: {
        ...step.data,
        valid,
        stepData: {
          fromEmail,
          toEmail,
          cc,
          bcc,
          subject,
          message,
          attachment
        },
        stepSettings: {
          fromEmail,
          cc,
          bcc,
          toEmail,
          subject,
          message,
          attachment
        },
        exposedFields: []
      }
    };
    dispatch(updateStep(newStep));
    dispatch(saveStepToServer(newStep));
  }

  // const gmailAuth = () => {
  //   const GAPI_CONFIG = {
  //     clientId:
  //       '617498649568-r8ar934gsohn7tvafsp7gcbmf9pktr8m.apps.googleusercontent.com',
  //     //SOURCE: https://stackoverflow.com/a/26940406
  //     scope:
  //       'openid profile email https://mail.google.com https://www.googleapis.com/auth/gmail.modify https://www.googleapis.com/auth/gmail.compose https://www.googleapis.com/auth/gmail.send'
  //   };
  //   const onSuccess = async (resp) => {
  //     const authCode = resp.code;
  //     const response = await server.post(`/apps/gmail/auth`, {
  //       code: authCode
  //     });
  //     if (response.data && response.data.success) {
  //       dispatch(fetchEmailApps());
  //     } else {
  //       console.log(response.error);
  //     }
  //   };
  //   const onFailure = (resp) => {
  //     console.log(resp);
  //   };
  //   //API: https://www.npmjs.com/package/react-google-login
  //   return (
  //     <GoogleLogin
  //       clientId={GAPI_CONFIG.clientId}
  //       buttonText="Add Gmail Account"
  //       responseType="code"
  //       scope={GAPI_CONFIG.scope}
  //       accessType="offline"
  //       onSuccess={onSuccess}
  //       onFailure={onFailure}
  //       cookiePolicy={'single_host_origin'}
  //     />
  //   );
  // };
  const [name, setName] = useState(step.name || props.defaultName);
  const Topbar = ({ name, setName }) => {
    return (
      <div className="step_bar">
        <nav class="level">
          <div class="level-left step-editable-name">
            <div class="level-item">
              <span className="step_name" title="Click to edit name">
                <Field
                  name="stepName"
                  component={(props) => (
                    <EditableLabel
                      onFocusOut={(text) => {
                        setName(text);
                        props.input.onChange(name);
                      }}
                      inputClassName="input"
                      text={name}
                    />
                  )}
                />
              </span>
            </div>
            <div class="level-item">
              <i class="fa fa-edit" />
            </div>
          </div>
          <div class="level-right">
            <button
              type="submit"
              disabled={pristine}
              className={`button is-small is-info ${
                pendingUpdate && pendingUpdate.pending ? 'is-loading' : ''
              }`}
            >
              Save
            </button>
          </div>
        </nav>
      </div>
    );
  };
  const { handleSubmit, pristine, submitting } = props;
  return (
    <div className="step_setting" ref={ref}>
      {/* <h5 className='title is-5'>Configure Email Notification</h5> */}
      <form onSubmit={handleSubmit(submit)} className="email_setting">
        <Topbar name={name} setName={setName} />
        <div className="fields">
          <label class="label">
            From Email <span className="has-text-grey">(required)</span>
          </label>
          <div class="field is-grouped">
            <div class="control is-expanded">
              <Field
                name="fromEmail"
                className="from is-fullwidth"
                component={ReSelect}
                validate={[
                  required({ msg: 'Please select an email account to use.' })
                ]}
                options={emailOptions}
                placeholder="Email"
              />
            </div>
            {/* <div className="control">{gmailAuth()}</div> */}
          </div>
          <p class="help pull-up">Which address to use to send the email?</p>
          {/* <article class="message is-warning">
            <div class="message-body">
              <strong>Note: </strong>Using Gmail account is an experimental
              feature as Docdown is not a Google verified vendor.
            </div>
          </article> */}
          <div class="field">
            <label class="label">
              To <span className="has-text-grey">(required)</span>
            </label>
            <div class="control">
              <Field
                name="toEmail"
                component={FieldSelect}
                width={x}
                multiple=","
                options={prevData}
                typeFilter="field"
                validate={[
                  required({
                    msg:
                      'Please provide a single or multiple comma separated recipient email address.'
                  }),
                  commaSeparatedEmails
                  //validateMerge
                ]}
                placeholder="Email"
              />
            </div>
            <p class="help">
              Who will this email be sent to? Click the + button to use data
              from previous steps. To add multiple email address, separate
              multiple email addresses with a comma eg.
              test@example.com,example@example.com.
            </p>
          </div>

          <div class="field">
            <label class="label">Cc</label>
            <div class="control">
              <Field
                name="cc"
                component={FieldSelect}
                width={x}
                multiple=","
                options={prevData}
                typeFilter="field"
                validate={[commaSeparatedEmails]}
                placeholder="Email"
              />
            </div>
            <p class="help">
              Who should be cc'd on this email? Click the + button to use data
              from previous steps. To add multiple email address, separate
              multiple email addresses with a comma eg.
              test@example.com,example@example.com.
            </p>
          </div>

          <div class="field">
            <label class="label">Bcc</label>
            <div class="control">
              <Field
                name="bcc"
                component={FieldSelect}
                width={x}
                multiple=","
                options={prevData}
                typeFilter="field"
                validate={[commaSeparatedEmails]}
                placeholder="Email"
              />
            </div>
            <p class="help">
              Who should be bcc'd on this email? Click the + button to use data
              from previous steps. To add multiple email address, separate
              multiple email addresses with a comma eg.
              test@example.com,example@example.com.
            </p>
          </div>

          <div class="field">
            <label class="label">
              Subject <span className="has-text-grey">(required)</span>
            </label>
            <div class="control">
              <Field
                name="subject"
                component={FieldSelect}
                width={x}
                multiple=" "
                options={prevData}
                typeFilter="field"
                placeholder="Subject"
                validate={[
                  required({
                    msg: 'Please provide the email subject'
                  })
                ]}
              />
            </div>
          </div>

          <div class="field">
            <label class="label">Attachment</label>
            <div class="control">
              <Field
                name="attachment"
                component={FieldSelect}
                showMenuOnTouch={true}
                width={x}
                options={prevData}
                exclusive={true}
                typeFilter="attachment"
                openMenuOnClick={false}
                placeholder="File"
              />
            </div>
            <p class="help">
              Click to select file generated from previous steps.
            </p>
          </div>

          <div class="field">
            <label class="label">Message</label>
            <div class="control">
              <Field name="message" component={TinyEditor} />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

const EmailSettings = reduxForm()(EmailSettingsComponent);
export default EmailSettings;
