/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

const FieldTabSelector = props => {
  let { value, onChange } = props;
  return (
    <div class="tabs is-small is-centered is-fullwidth">
      <ul>
        <li class={value === 'data' && `is-active`} onClick={e => { onChange('data') }}>
          <a>
            <span class="icon is-small">
              <i class="fas fa-database"></i>
            </span>
            <span>General</span></a>
        </li>
        <li class={value === 'appearance' && `is-active`} onClick={e => { onChange('appearance') }}>
          <a>
            <span className="icon is-small">
              <i className="fas fa-print"></i>
            </span>
            <span>Conditional</span></a>
        </li>
      </ul>
    </div>
  )
}
export default FieldTabSelector