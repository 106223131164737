import React from 'react';
import isUrl from 'is-valid-http-url';

export const traverseObject = (objj, apply) => {
  if (objj === null) {
    console.log(objj);
    return;
  }
  Object.keys(objj).forEach((key) => {
    if (typeof objj[key] === 'object') {
      traverseObject(objj[key], apply);
    }
    if (Array.isArray(objj[key])) {
      objj[key].forEach((item) => traverseObject(item, apply));
    }
    if (typeof objj[key] === 'string') {
      objj[key] = apply(objj[key]);
    }
  });
};

export const flatten = (obj, path = '') => {
  if (!(obj instanceof Object)) return { [path.replace(/\.$/g, '')]: obj };

  return Object.keys(obj).reduce((output, key) => {
    return obj instanceof Array
      ? { ...output, ...flatten(obj[key], path + '[' + key + '].') }
      : { ...output, ...flatten(obj[key], path + key + '.') };
  }, {});
};

export const isValidImage = (str) => {
  try {
    let imgStr = str.split(',')[1];
    //SOURCE: https://stackoverflow.com/a/35002237
    let base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
    if (base64regex.test(imgStr)) {
      return true;
    } else return false;
  } catch (err) {
    return false;
  }
};

export const isValidURL = (str) => {
  return isUrl(str);
};

export const getStatusIcon = (status) => {
  switch (status) {
    case 'COMPLETED': {
      return (
        <span class="icon status is-medium">
          <i
            className="fas fa-check-circle"
            style={{ marginRight: '10px' }}
          ></i>
        </span>
      );
    }
    case 'FAILED': {
      return (
        <span class="icon status is-medium">
          <i
            className="fas fa-times-circle"
            style={{ marginRight: '10px' }}
          ></i>
        </span>
      );
    }
    case 'CANCELED': {
      return (
        <span class="icon status is-medium">
          <i className="fas fa-ban" style={{ marginRight: '10px' }}></i>
        </span>
      );
    }
    case 'RUNNING':
    default: {
      return (
        <span class="icon status is-medium">
          <i className="fas fa-clock" style={{ marginRight: '10px' }}></i>
        </span>
      );
    }
  }
};
