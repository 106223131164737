/* eslint-disable jsx-a11y/anchor-is-valid */
import useOnClickOutside from 'hooks/useOnClickOutside';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingBar from 'react-redux-loading-bar';
import { Link, useLocation } from 'react-router-dom';
import { logout } from 'redux/actions';
import { getUser, isLoggedIn } from 'redux/selectors';
import './Navbar.scss';

const Navbar = (props) => {
  const ref = useRef();
  const dispatch = useDispatch();
  const location = useLocation();

  const user = useSelector(getUser);
  const isUserLoggedIn = useSelector(isLoggedIn);

  const [menuOpen, setMenuOpen] = useState(false);

  useOnClickOutside(ref, () => {
    setMenuOpen(false);
  });

  const loggedIn = () => {
    const renderLoggedOut = () => {
      return (
        <div class="navbar-item">
          <Link to="/login" className="is-small">
            Login
          </Link>
        </div>
      );
    };

    const renderLoggedIn = () => {
      return (
        <React.Fragment>
          <div class="navbar-item">
            <div
              class={`dropdown is-right ${menuOpen && 'is-active'}`}
              ref={ref}
            >
              <div class="dropdown-trigger">
                <a onClick={(e) => setMenuOpen(!menuOpen)}>
                  <span>My Account</span>
                  <span class="icon is-small">
                    <i class="fas fa-angle-down" aria-hidden="true"></i>
                  </span>
                </a>
                <div class="dropdown-menu" id="dropdown-menu2" role="menu">
                  <div class="dropdown-content">
                    <div className="dropdown-item">
                      <p>{user.email}</p>
                    </div>
                    <Link
                      to="/settings/account"
                      class="dropdown-item"
                      onClick={(e) => {
                        setMenuOpen(false);
                      }}
                    >
                      <span class="icon">
                        <i class="fas fa-user-circle" aria-hidden="true"></i>
                      </span>
                      <span>Settings</span>
                    </Link>
                    <Link
                      to="/logout"
                      class="dropdown-item"
                      onClick={(e) => {
                        dispatch(logout());
                        setMenuOpen(false);
                      }}
                    >
                      <span class="icon">
                        <i class="fas fa-sign-out-alt" aria-hidden="true"></i>
                      </span>
                      <span>Logout</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    };
    if (props.loading) {
      return <p>loading...</p>;
    } else {
      return isUserLoggedIn ? renderLoggedIn() : renderLoggedOut();
    }
  };

  const protectedRoutes = () => {
    return (
      <React.Fragment>
        <div
          className={`navbar-item ${
            location.pathname === '/' ? 'active_page' : ''
          }`}
        >
          <span>
            <Link to="/">
              <i className="fas fa-home"></i>Dashboard
            </Link>
          </span>
        </div>
        <div
          className={`navbar-item ${
            location.pathname.includes('/documents') ? 'active_page' : ''
          }`}
        >
          <span>
            <Link to="/documents">
              <i className="fas fa-file"></i> Documents
            </Link>
          </span>
        </div>
        <div
          className={`navbar-item ${
            location.pathname.includes('/workflows') ? 'active_page' : ''
          }`}
        >
          <span>
            <Link to="/workflows">
              <i className="fas fa-stream"></i> Workflows
            </Link>
          </span>
        </div>
        {['owner', 'admin'].includes(user.role) && (
          <div
            className={`navbar-item ${
              location.pathname.includes('/activity_log') ? 'active_page' : ''
            }`}
          >
            <span>
              <Link to="/activity_log">
                <i className="fas fa-history"></i> Activity Log
              </Link>
            </span>
          </div>
        )}
      </React.Fragment>
    );
  };

  const [mobilemenu, setMobilemenu] = useState(false);
  const render = () => {
    return (
      <React.Fragment>
        <LoadingBar
          className="loadingbar"
          updateTime={100}
          maxProgress={97}
          progressIncrease={3}
        />
        <nav
          className="navbar is-fixed-top topbar"
          role="navigation"
          aria-label="main navigation"
        >
          <div className="navbar-brand">
            <div className="navbar-item">
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M22 24h-20l-2-10h24l-2 10zm-1.444-22.001l.439-1.999h-17.994l.439 1.999h17.116zm1.7 4.001l.37-2h-21.256l.371 2h20.515zm-19.731 6l-.254-2h19.45l-.262 2h2.017l.524-4h-24l.509 4h2.016z" />
              </svg>
              <span> Docdown</span> */}
              <img src="/assets/docdown.png" class="sidebar_logo" />
            </div>
            <div
              class={`navbar-burger ${mobilemenu && 'is-active is-mobile'}`}
              onClick={(e) => setMobilemenu(!mobilemenu)}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
          <div className="container">
            <div class={`navbar-menu ${mobilemenu && 'is-active is-mobile'}`}>
              <div className="navbar-start">
                {isUserLoggedIn && protectedRoutes()}
              </div>
            </div>
          </div>
          <div class="navbar-end">{loggedIn()}</div>
        </nav>
      </React.Fragment>
    );
  };

  let display = true;
  props.exclude &&
    props.exclude.map((item) => {
      if (location.pathname.includes(item)) {
        display = false;
      }
    });
  return display ? render() : null;
};

export default Navbar;
