import server from 'api/server';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import Modal from 'react-responsive-modal';
import ReactSelect from 'react-select';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

export const SVInviteModal = ({ open, onClose, inviter, onSucccess }) => {
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getSVEmployees = async () => {
      setFetching(true);
      try {
        const { data } = await server.get('/apps/smartvault/employees');
        setEmployees(data);
      } catch (err) {
        console.log(err);
      }
      setFetching(false);
    };
    if (open) {
      getSVEmployees();
    }
  }, [open]);

  return (
    <Modal open={open} onClose={onClose} closeOnOverlayClick={false}>
      <div class="invite_member_modal">
        <h3 class="title is-h3">Invite SmartVault Team Member</h3>
        <p>
          Please use the drop down to select the employee from your associated
          SmartVault account who you wish to give access to. Then choose the
          access level and click Send Invitation.
        </p>
        <Formik
          initialValues={{
            name: '',
            email: '',
            role: 'member',
            svCustomer: true
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required('Required'),
            email: Yup.string().email('Invalid email').required('Required')
          })}
          onSubmit={async ({ name, email, role }) => {
            try {
              setError(null);
              setLoading(true);
              await server.post('/company/invite', {
                name,
                email,
                role,
                inviter,
                svCustomer: true
              });
              toast.info('Member has been invited to join!');
              onClose();
              setLoading(false);
              await onSucccess();
            } catch (err) {
              setLoading(false);
              if (
                err.response &&
                err.response.data &&
                err.response.data.message
              )
                setError(err.response.data.message);
              else {
                console.log(err);
                onClose();
                toast.info('Could not invite team member!');
              }
            }
          }}
        >
          {({ errors, touched }) => (
            <Form class="">
              <div class="field">
                <label class="label">Employee</label>
                <div class="control employee_select">
                  <Field name="name">
                    {({ field: { value }, form: { setFieldValue } }) => (
                      <ReactSelect
                        options={employees.map((e) => ({
                          value: e.email,
                          label: `${e.name} (${e.email})`
                        }))}
                        onChange={(val) => {
                          const name = val.label.split('(')[0].trim();
                          const email = val.value;
                          setFieldValue('name', name);
                          setFieldValue('email', email);
                        }}
                        isLoading={fetching}
                        isSearchable={true}
                      />
                    )}
                  </Field>
                  {errors.name && touched.name ? (
                    <p class="help is-danger">{errors.name}</p>
                  ) : null}
                </div>
              </div>
              <div class="field">
                <label class="label">Access</label>
                <div class="control">
                  <Field name="role">
                    {({ field: { value }, form: { setFieldValue } }) => (
                      <>
                        <div class="select">
                          <select
                            value={value}
                            onChange={(e) =>
                              setFieldValue('role', e.target.value)
                            }
                          >
                            <option value="member">Member</option>
                            <option value="admin">Admin</option>
                          </select>
                        </div>
                      </>
                    )}
                  </Field>
                </div>
              </div>

              {error && <p class="help is-danger">{error}</p>}
              <hr />
              <div class="field">
                <div class="control">
                  <button
                    id="SVInviteModal-button-invite"
                    type="submit"
                    class={`button is-pulled-right is-info ${
                      loading ? 'is-loading' : ''
                    }`}
                  >
                    Send Invitation
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};
