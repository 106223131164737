/* eslint-disable jsx-a11y/anchor-is-valid */
import server from 'api/server';
import Page from 'components/Page';
import availableSteps from 'components/WorkflowEditor/steps/availableSteps';
import DownloadButton from 'components/_common/DownloadButton';
import React, { useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import Moment from 'react-moment';
import { flatten, getStatusIcon, isValidImage, isValidURL } from './utils';

const DataBox = ({ src }) => {
  const processKey = (item) => {
    return item.includes('.') ? item.split('.').slice(-1)[0] : item;
  };
  const processValue = (item) => {
    if (isValidImage(item)) return <img src={item} />;
    if (typeof item === 'object' && item != null)
      return <code>{JSON.stringify(item)}</code>;

    if (isValidURL(String(item)) && String(item).includes('.pdf')) {
      const [fileName, extension] = item.split('/').pop().split('.');
      return (
        <DownloadButton
          classes={'button is-small'}
          url={item}
          fileName={fileName}
          extension={extension}
        />
      );
    }
    if (typeof item === 'string') return `"${item}"`;
  };

  let data;
  if (typeof src === 'object' && src !== null) {
    const preData = Object.assign({}, src);
    Object.keys(preData).forEach((step) => {
      if (preData[step]['_trackingLogData'])
        delete preData[step]['_trackingLogData'];
    });
    data = flatten(preData);
  } else {
    data = src;
  }

  return (
    <div class="schema-container">
      {typeof src === 'object' && src !== null && (
        <>
          {' '}
          {Object.keys(data)
            .filter(
              (d) => !['true', 'false', 'dateOfSubmission', ''].includes(d)
            )
            .map((d) => (
              <p>
                <span class="schema-key">{processKey(d)}</span>:{' '}
                <span class="schema-value">{processValue(data[d])}</span>
              </p>
            ))}
        </>
      )}
      {typeof src === 'string' && (
        <p>
          <span class="schema-key">Message</span>:{' '}
          <span class="schema-value">{src}</span>
        </p>
      )}
    </div>
  );
};

const StepDetails = ({ step }) => {
  const [tab, setTab] = useState('data-in');

  const renderTab = () => {
    switch (tab) {
      case 'data-in': {
        return <DataBox src={step.input} />;
      }
      case 'data-out': {
        return <DataBox src={step.output} />;
      }
      case 'errors': {
        // console.log(step.error);
        return <DataBox src={step.error} />;
      }
      default:
        return null;
    }
  };

  return (
    <div className="box">
      <h4 class="title is-5">
        {getStatusIcon(step.error ? 'FAILED' : step.status)} {step.order}.{' '}
        {availableSteps.find((s) => s.type === step.type).name}
      </h4>
      <p class="step_run_date">
        <Moment format="lll">{step.createdAt}</Moment>
      </p>
      <div class="tabs">
        <ul>
          <li class={tab === 'data-in' ? 'is-active' : ''}>
            <a onClick={(e) => setTab('data-in')}>Data In</a>
          </li>
          <li class={tab === 'data-out' ? 'is-active' : ''}>
            <a onClick={(e) => setTab('data-out')}>Data Out</a>
          </li>
          <li class={tab === 'errors' ? 'is-active' : ''}>
            <a onClick={(e) => setTab('errors')}>Errors</a>
          </li>
        </ul>
      </div>
      {renderTab()}
    </div>
  );
};

const Run = (props) => {
  const workflowRunId = props.match.params.workflowRunId;
  const [data, setData] = useState(null);
  useEffect(() => {
    const fetchWorkflowRun = async (workflowRunId) => {
      try {
        const { data } = await server.get(`/workflows/runs/${workflowRunId}`);
        setData(data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchWorkflowRun(workflowRunId);
  }, [workflowRunId]);

  const renderLoading = () => {
    return (
      <div className="loader_container">
        <Loader type="Oval" color="#ccc" height={30} width={30} />
      </div>
    );
  };

  const render = () => {
    return (
      <div className="history_run">
        <section class="section">
          <div class="container">
            <nav class="level">
              <div class="level-left">
                <div class="level-item">
                  <h4 class="title is-4">
                    {getStatusIcon(data.status)}
                    {data.workflow.name}
                  </h4>
                </div>
              </div>
              <div class="level-right">
                <div class="level-item">
                  <p class="workflow_run_id">
                    <strong>ID</strong>: {data.id}
                  </p>
                </div>
              </div>
            </nav>
          </div>
        </section>
        <div class="container">
          {data.stepRun
            .sort((a, b) => a.order - b.order)
            .map((step) => {
              if (typeof step === 'object' && step !== null) {
                let output = {};
                // This is to filter out keys in input data from output data
                if (step.output) {
                  Object.keys(step.output).forEach((o) => {
                    for (const order in step.input) {
                      if (
                        !Object.keys(step.input[order]).includes(o) ||
                        o === 'generatedDocument'
                      )
                        output[o] = step.output[o];
                    }
                  });
                }
                const newStep = { ...step, output };
                return <StepDetails step={newStep} />;
              }
            })}
        </div>
      </div>
    );
  };

  return (
    <Page title="Workflow History">
      <div className="history_run_container">
        {data ? render() : renderLoading()}
      </div>
      {/* <Footer /> */}
    </Page>
  );
};
export default Run;
