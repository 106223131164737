import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Field, reduxForm, isValid, getFormValues } from 'redux-form';
import EditableLabel from 'react-inline-editing';
import { required, url, combine } from 'redux-form-validators';

import TextField from './../components/TextField';
import { ReSelect, FieldSelect } from './../components/Select';
import KeyValuePair from './../components/KeyValuePair';

import useExposedFields from './useExposedFields';
import useDimensions from 'hooks/useDimensions';

import { isActionPending } from 'redux/selectors';
import { updateStep, saveStepToServer } from 'redux/actions/workflowActions';

const OutgoingWebhook = (props) => {
  const pendingUpdate = useSelector((state) =>
    isActionPending(state, 'UPDATE_STEP')
  );
  const step = props.step;
  const prevData = useExposedFields(step.order);
  const dispatch = useDispatch();
  const valid = useSelector((state) => isValid(props.form)(state));
  const currentValues = useSelector((state) =>
    getFormValues(props.form)(state)
  );
  const [ref, { x }] = useDimensions(true);

  let getRequest =
    currentValues &&
    currentValues.requestType &&
    currentValues.requestType.value === 'get';

  useEffect(() => {
    props.initialize(props.initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function submit(values) {
    let {
      requestType,
      url,
      payloadType,
      data,
      file,
      wrapInArray,
      headers
    } = values;
    if (!payloadType || !payloadType.value) {
      payloadType = { value: 'form', label: 'Form' };
    }
    const newStep = {
      ...step,
      name,
      data: {
        ...step.data,
        valid,
        stepData: {
          requestType,
          url,
          payloadType,
          data,
          file,
          wrapInArray,
          headers
        },
        stepSettings: {
          requestType,
          url,
          payloadType,
          data,
          file,
          wrapInArray,
          headers
        },
        // exposedFields: [...responseData.map(d => ({ type: 'field', name: d.key }))]
        exposedFields: []
      }
    };
    dispatch(updateStep(newStep));
    dispatch(saveStepToServer(newStep));
  }

  const [name, setName] = useState(step.name || props.defaultName);

  const Topbar = ({ name, setName }) => {
    return (
      <div className="step_bar">
        <nav class="level">
          <div class="level-left step-editable-name">
            <div class="level-item">
              <span className="step_name" title="Click to edit name">
                <Field
                  name="stepName"
                  component={(props) => (
                    <EditableLabel
                      onFocusOut={(text) => {
                        setName(text);
                        props.input.onChange(name);
                      }}
                      inputClassName="input"
                      text={name}
                    />
                  )}
                />
              </span>
            </div>
            <div class="level-item">
              <i class="fa fa-edit" />
            </div>
          </div>
          <div class="level-right">
            <button
              type="submit"
              disabled={pristine}
              className={`button is-small is-info ${
                pendingUpdate && pendingUpdate.pending ? 'is-loading' : ''
              }`}
            >
              Save
            </button>
          </div>
        </nav>
      </div>
    );
  };

  const { handleSubmit, pristine, submitting } = props;
  return (
    <div className="step_setting" ref={ref}>
      {/* <h5 className='title is-5'>Configure Email Notification</h5> */}
      <form onSubmit={handleSubmit(submit)} className="email_setting">
        <Topbar name={name} setName={setName} />
        <div className="fields">
          <div class="field">
            <div class="control">
              <Field
                name="url"
                component={TextField}
                label="URL"
                placeholder="Webhook URL"
                required
                validate={[required(), url({ protocols: ['http', 'https'] })]}
              />
            </div>
            <p class="help">URL to send HTTP request.</p>
          </div>

          <div class="field">
            <label className="label">
              Request Type <span class="has-text-grey">(required)</span>
            </label>
            <div class="control">
              <Field
                name="requestType"
                component={ReSelect}
                options={[
                  { value: 'get', label: 'GET' },
                  { value: 'post', label: 'POST' },
                  { value: 'put', label: 'PUT' },
                  { value: 'patch', label: 'PATCH' },
                  { value: 'delete', label: 'DELETE' }
                ]}
                validate={[required({ msg: 'Please specify request type.' })]}
              />
            </div>
          </div>

          {!getRequest && (
            <div class="field">
              <label className="label">
                Payload Type <span class="has-text-grey">(required)</span>
              </label>
              <div class="control">
                <Field
                  name="payloadType"
                  component={ReSelect}
                  options={[
                    { value: 'form', label: 'Form' },
                    { value: 'json', label: 'JSON' }
                  ]}
                  validate={[required({ msg: 'Please specify payload type.' })]}
                />
              </div>
            </div>
          )}

          {!getRequest && (
            <div class="field">
              <label className="label">
                Wrap Request Data in Array{' '}
                <span class="has-text-grey">(required)</span>
              </label>
              <div class="control">
                <Field
                  name="wrapInArray"
                  component={ReSelect}
                  options={[
                    { value: 'yes', label: 'Yes' },
                    { value: 'no', label: 'No' }
                  ]}
                  validate={[required()]}
                />
              </div>
              <p class="help">
                Wraps the data in an array if that is something the service
                requires.
              </p>
            </div>
          )}
          <div class="field">
            <label className="label">
              {getRequest ? 'Query Params' : 'Data'}
            </label>
            <div class="control">
              <Field name="data" component={KeyValuePair} prevData={prevData} />
            </div>
            {!getRequest && (
              <p class="help">
                These are key-value pairs that will form the data portion of the
                request. You can also create nested objects by using dot
                operator with key to define object hierarchy.
              </p>
            )}
            {getRequest && (
              <p class="help">
                These are key-value pairs that will be encoded into the request
                url. Nested data are not supported with GET query params.
              </p>
            )}
          </div>

          {!getRequest && (
            <div class="field">
              <label class="label">File</label>
              <div class="control">
                <Field
                  name="file"
                  component={FieldSelect}
                  showMenuOnTouch={true}
                  width={x}
                  options={prevData}
                  exclusive={true}
                  typeFilter="attachment"
                  openMenuOnClick={false}
                  placeholder="File"
                />
              </div>
              <p class="help">
                A file object to be attached to the request. The request will
                automatically be converted to <code>multipart/form-data</code>,
                with the above payload being passed as form data. Nested payload
                data are not supported with file request.
              </p>
            </div>
          )}

          <div class="field">
            <label className="label">Headers</label>
            <div class="control">
              <Field
                name="headers"
                keyPlaceholder="Header"
                component={KeyValuePair}
                prevData={prevData}
              />
            </div>
            <p className="help">
              Key, value pairs to be added as headers in all requests.
            </p>
          </div>

          {/* <hr />
          <div class="field">
            <label className='label'>Response Data</label>
            <div class="control">
              <Field name='responseData' component={KeyValuePair}
                keyPlaceholder='Name' valuePlaceholder='Response Object Key' />
            </div>
            <p className='help'>Define keys of response data of this request if you want to
            use them in the next steps. The workflow will fail if the keys aren't found in response
            object so please be careful with the mapping.</p>
          </div> */}
        </div>
      </form>
    </div>
  );
};

const OutgoingWebhookSettings = reduxForm()(OutgoingWebhook);
export default OutgoingWebhookSettings;
