import React from 'react';
import { useDispatch } from 'react-redux';
import Modal from 'react-responsive-modal';
import { toast } from 'react-toastify';
import { cloneWorkflow } from 'redux/actions/workflowActions';
import './WorkflowBox.scss';

const DuplicateWorkflowModal = ({ open, onClose, name, id }) => {
  const dispatch = useDispatch();

  const onPress = () => {
    dispatch(cloneWorkflow(id));
    toast.info('Workflow duplicated successfully');
    onClose();
  };
  return (
    <Modal open={open} onClose={onClose} closeOnOverlayClick={false}>
      <h3 class="title is-h3">Duplicate Workflow</h3>

      <p>{`Are you sure you want to duplicate workflow ${name}?`}</p>
      <hr />
      <div class="field is-grouped">
        <div class="control">
          <button class="button is-small is-info" onClick={onPress}>
            Duplicate
          </button>
        </div>
        <div class="control">
          <button class="button is-small" onClick={(e) => onClose()}>
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};
export default DuplicateWorkflowModal;
