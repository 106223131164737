import server from 'api/server';
import Page from 'components/Page';
import * as moment from 'moment';
import React, { useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { Link } from 'react-router-dom';
import { fetchApps } from 'redux/actions/appActions';
import { getUser, isActionPending } from 'redux/selectors';
import './App.scss';
import CustomEmailAddModal from './CustomEmail/AddModal';
import SVAddModal from './SmartVault/AddModal';
import { getAppLogo, getAppMeta, getStatusTag } from './utils';

const DisconnectAppModal = (props) => {
  const dispatch = useDispatch();

  const [safe, setSafe] = useState(false);
  const [disconnecting, setDisconnecting] = useState(false);

  useEffect(() => {
    async function checkSafeToDelete() {
      try {
        const response = await server.post('/apps/disconnect/safe', {
          id: props.id
        });
        if (response.data.safe) {
          setSafe(true);
        } else {
          setSafe(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
    checkSafeToDelete();
  }, [props.open]);

  const handleDisconnect = async () => {
    const disconnect = async (endpoint) => {
      try {
        await server.post(endpoint, { appId: props.id });
        dispatch(fetchApps());
        setDisconnecting(false);
        setSafe(false);
        props.onClose();
        return;
      } catch (error) {
        console.log(error);
        setDisconnecting(false);
        return;
      }
    };
    if (safe) {
      setDisconnecting(true);
      switch (props.type) {
        case 'gmail':
          await disconnect('/apps/gmail/disconnect');
        case 'drive':
          await disconnect('/apps/drive/disconnect');
        case 'customEmail':
          await disconnect('/apps/email/delete');
        case 'smartvault':
          await disconnect('/apps/smartvault/disconnect');
        default:
          return null;
      }
    }
  };
  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      closeOnOverlayClick={false}
    >
      <div class="modal_top">
        <h3 class="title">Remove app?</h3>
        <p>
          {safe &&
            "This will revoke Docdown's access to this integration. You can always connect it back later."}
          {!safe &&
            'This integration is used in one or more workflows and cannot be removed.'}
        </p>
        <hr />
        <div class="field is-grouped">
          <div class="control">
            <button
              disabled={!safe}
              class={`button is-small is-danger ${
                disconnecting ? 'is-loading' : ''
              }`}
              onClick={handleDisconnect}
            >
              Remove
            </button>
          </div>
          <div class="control">
            <button
              class="button is-small is-danger is-light"
              onClick={(e) => props.onClose()}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const AddNewIntegrationModal = (props) => {
  const renderApp = (app) => {
    return (
      <div
        class="new-integration-block"
        onClick={() => {
          app.onClick();
          props.onClose();
        }}
      >
        <article class="media">
          <figure class="media-left">
            <p class="image is-64x64">
              <img src={app.image} />
            </p>
          </figure>
          <div class="media-content">
            <div class="content">
              <p>
                <span>
                  <strong>{app.name}</strong>
                </span>
              </p>
              <p className="app-description">{app.description}</p>
            </div>
          </div>
        </article>
      </div>
    );
  };
  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      closeOnOverlayClick={false}
    >
      <div class="modal_top">
        <h3 class="title">Add New Integration</h3>
        {props.integrations.map((app) => renderApp(app))}
      </div>
    </Modal>
  );
};

const AppBar = (props) => {
  const [openDisconnectModal, setOpenDisconnectModal] = useState(false);
  const { label, logo, settings } = getAppMeta(props.app.type);
  return (
    <React.Fragment>
      <DisconnectAppModal
        id={props.app.id}
        type={props.app.type}
        open={openDisconnectModal}
        onClose={(e) => setOpenDisconnectModal(false)}
      />

      <article class="media">
        <figure class="media-left">
          <p class="image is-64x64">{logo}</p>
        </figure>
        <div class="media-content">
          <div class="content">
            <p>
              <span>
                <strong>{label}</strong> {props.app.name}{' '}
                {props.app.status && getStatusTag(props.app.status)}
              </span>
            </p>
            <p className="has-text-grey">
              Added {moment(props.app.createdAt).fromNow()}
            </p>
          </div>
        </div>
        <div class="media-right">
          {settings && (
            <Link
              to={`/integrations/${props.app.id}`}
              class="button is-light is-small margin-right-5"
            >
              <i class="fas fa-cog"></i> Settings
            </Link>
          )}
          <a
            onClick={(e) => setOpenDisconnectModal(true)}
            className="button is-danger is-light is-small"
          >
            <i class="fas fa-unlink"></i> Remove
          </a>
        </div>
      </article>
    </React.Fragment>
  );
};

const Apps = (props) => {
  const [openNewIntegrationModal, setOpenNewIntegrationModal] = useState(false);
  const [emailOpen, setEmailOpen] = useState(false);
  const [SVOpen, setSVOpen] = useState(false);

  const onOpenEmailModal = () => setEmailOpen(true);
  const onCloseEmailModal = () => setEmailOpen(false);

  const onOpenSVModal = () => setSVOpen(true);
  const onCloseSVModal = () => setSVOpen(false);

  const pending = useSelector((state) => isActionPending(state, 'GET_APPS'));
  const dispatch = useDispatch();
  const user = useSelector(getUser);

  useEffect(() => {
    dispatch(fetchApps());
  }, [emailOpen, SVOpen]);

  let apps = useSelector((state) => state.apps.all);
  if (apps) apps = apps.filter((a) => !a.hidden);

  let integrations = [
    {
      name: 'SmartVault',
      description:
        'Safely store your most sensitive documents with bank level security.',
      image: getAppLogo('smartvault'),
      onClick: onOpenSVModal
    },
    {
      name: 'Custom Email',
      description:
        'Send notification and workflow emails from your own domain name.',
      image: getAppLogo('customEmail'),
      onClick: onOpenEmailModal
    }
  ];
  if (user.svCustomer)
    integrations = integrations.filter((i) => i.name !== 'SmartVault');

  const renderEmpty = () => {
    if (!pending?.pending) {
      return (
        <div class="container is-mobile">
          <div className="empty">
            <div class="content">
              <p>
                <i className="fas fa-th"></i>
              </p>
              <p>You haven't connected any integrations.</p>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="loader_container">
          <Loader type="Oval" color="#ccc" height={30} width={30} />
        </div>
      );
    }
  };

  return (
    <Page
      title="Integrations"
      action={
        <button
          onClick={(e) => {
            setOpenNewIntegrationModal(true);
          }}
          className="button upload is-small is-info margin-left-10"
        >
          <span class="icon">
            <i class="fas fa-plus"></i>
          </span>
          <span>New Integration</span>
        </button>
      }
    >
      <>
        <AddNewIntegrationModal
          open={openNewIntegrationModal}
          onClose={() => setOpenNewIntegrationModal(false)}
          integrations={integrations}
        />
        <CustomEmailAddModal open={emailOpen} onClose={onCloseEmailModal} />
        <SVAddModal open={SVOpen} onClose={onCloseSVModal} />
      </>
      <div className="page settings_page">
        <div class="container">
          <div className="box page-box">
            <div className="apps">
              {apps && apps.length > 0
                ? apps.map((app) => <AppBar app={app} />)
                : renderEmpty()}
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};
export default Apps;
