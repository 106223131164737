import React from 'react';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import { setFields } from 'redux/actions';

const MultiSelect = (props) => {
  const {
    input: { value, onChange },
    fields,
    field
  } = props;
  const dispatch = useDispatch();

  return (
    <div className="field font_select is-horizontal">
      <div class="field-label is-small">
        <label for={props.label} class="label">
          {props.label}
        </label>
      </div>
      <div class="field-body">
        <p class="control is-expanded select_control">
          <span class="select is-small is-fullwidth is-select">
            <Select
              components={{
                DropdownIndicator: () => <i class="fas fa-chevron-down"></i>
              }}
              isMulti
              isSearchable
              isClearable={false}
              options={props.options}
              value={value}
              onChange={(val) => {
                onChange(val);
                const idx = fields.findIndex(
                  (f) => f.data.id === field.data.id
                );
                const newFields = [...fields];
                newFields[idx]['data']['fields'] = val;
                dispatch(setFields(fields));
              }}
            />
          </span>
          {props.tooltip && (
            <i class="fas fa-question-circle" data-tip={props.tooltip}></i>
          )}
        </p>
      </div>
      <ReactTooltip
        className="field_tooltip"
        place="left"
        effect="solid"
        offset={{ top: 0 }}
        multiline={true}
      />
    </div>
  );
};

export default MultiSelect;
