import { Rnd } from '@docdown/react-rnd';
import useRefDimensions from 'hooks/useRefDimensions';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveDocumentToServer, setActiveField, setFields } from 'redux/actions';
import { getDocument, getFields } from 'redux/selectors';
import useDeepCompareEffect from 'use-deep-compare-effect';
import './Annotation.scss';

const Rectangle = (props) => {
  const dispatch = useDispatch();
  const [dimUpdated, setDimUpdated] = useState(true);
  const currentDocument = useSelector((state) =>
    getDocument(state, props.documentId)
  );
  const fields = useSelector((state) => getFields(state));
  const parentDims = useRefDimensions(props.containerRef);

  const [isDragging, setIsDragging] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  // Variable to store Rnd instance.
  let rndRef;

  useDeepCompareEffect(() => {
    const {
      geometry: { x, y, width, height }
    } = props.annotation;
    rndRef.updateSize({
      width: `${width}%`,
      height: `${height}%`
    });
    rndRef.updatePosition({
      x: (x / 100) * parentDims.width,
      y: (y / 100) * parentDims.height
    });
    setDimUpdated(false);
  }, [
    parentDims,
    dimUpdated,
    props.annotation.geometry.x,
    props.annotation.geometry.y,
    props.annotation.geometry.width,
    props.annotation.geometry.height
  ]);

  const handleOnDragStop = async (event, data) => {
    // event.stopImmediatePropagation();

    const { x, y } = data;
    let xPercent = (x * 100) / parentDims.width;
    let yPercent = (y * 100) / parentDims.height;

    let idx = fields.findIndex((f) => f.data.id === props.annotation.data.id);

    let updatedFields = [...fields];
    updatedFields[idx] = {
      geometry: { ...props.annotation.geometry, x: xPercent, y: yPercent },
      data: { ...props.annotation.data, x: xPercent, y: yPercent }
    };
    dispatch(setFields(updatedFields));
    dispatch(
      saveDocumentToServer({ ...currentDocument, fields: updatedFields }, false)
    );
    setDimUpdated(true);
  };

  const handleOnResizeStop = async (e, direction, ref, delta, position) => {
    // event.stopImmediatePropagation();

    const width = ref.offsetWidth;
    const height = ref.offsetHeight;
    const { x, y } = position;
    let wPercent = (width * 100) / parentDims.width;
    let hPercent = (height * 100) / parentDims.height;
    let xPercent = (x * 100) / parentDims.width;
    let yPercent = (y * 100) / parentDims.height;
    //check if change is significant enoughj
    let updatedPosition = {
      x: xPercent,
      y: yPercent,
      width: wPercent,
      height: hPercent
    };
    let idx = fields.findIndex((f) => f.data.id === props.annotation.data.id);
    let updatedFields = [...fields];
    updatedFields[idx] = {
      geometry: { ...props.annotation.geometry, ...updatedPosition },
      data: { ...props.annotation.data, ...updatedPosition }
    };
    dispatch(setFields(updatedFields));
    dispatch(
      saveDocumentToServer({ ...currentDocument, fields: updatedFields }, false)
    );
    setDimUpdated(true);
  };

  let rectHeight = (props.annotation.geometry.height / 100) * parentDims.height;
  const render = () => {
    const { geometry, data } = props.annotation;
    if (!geometry) return null;
    return (
      <>
        <Rnd
          ref={(c) => {
            rndRef = c;
          }}
          enableResizing={!props.disableResizing}
          disableDragging={props.disableDragging}
          scale={props.scale}
          directBounds={{
            top: 0,
            left: 0,
            bottom: props.containerHeight,
            right: props.containerWidth
          }}
          onClick={() => {
            dispatch(setActiveField(props.annotation));
          }}
          onDoubleClick={() => {
            dispatch(setActiveField(props.annotation));
          }}
          onDragStart={() => {
            setIsDragging(true);
          }}
          onDragStop={(event, data) => {
            setIsDragging(false);
            handleOnDragStop(event, data);
          }}
          onResizeStart={() => {
            setIsResizing(true);
          }}
          onResizeStop={(e, direction, ref, delta, position) => {
            setIsResizing(false);
            handleOnResizeStop(e, direction, ref, delta, position);
          }}
          className={`rectangle_annotation`}
          id={data.id}
          bounds=".document_container"
          style={{
            zIndex: 999,
            backgroundColor: props.active && 'rgba(20%, 52%, 66%, 0.6)',
            border: props.active && 'dashed 2px #3284A8',
            ...props.style
          }}
        >
          {data.comb && (
            <div
              className="rectangle_annotation_comb"
              style={{ width: `${geometry.width}%` }}
            >
              {data.combLength &&
                !isResizing &&
                [...Array(parseInt(data.combLength))].map((_, i) => (
                  <div
                    key={i}
                    style={{
                      minHeight: `${
                        (geometry.height / 100) * parentDims.height - 4
                      }px`
                    }}
                    className="rectangle_annotation_comb_box"
                  ></div>
                ))}
            </div>
          )}
          {!isResizing && (
            <>
              {data.type !== 'checkbox' && !data.comb && (
                <div class="rectangle_top_name">
                  <span>
                    <strong>{!data.comb && renderIcon(props)}</strong>
                    <span>{'  '}</span>
                    <span>
                      {data.type === 'checkbox' || data.comb ? '' : data.label}
                    </span>
                  </span>
                </div>
              )}
              {/* <div
                className={`rectangle_annotation_label has-text-centered`}
                style={dynamicStyle(props, rectHeight)}
              >
                <span>
                  <strong>{!data.comb && renderIcon(props)}</strong>
                  <span>{'  '}</span>
                  <span>
                    {data.type === 'checkbox' || data.comb ? '' : data.label}
                  </span>
                </span>
              </div> */}
            </>
          )}
        </Rnd>
        {/* {props.active && (
          <div
            style={{
              position: 'absolute',
              top: `${geometry.y}%`,
              left: `${geometry.x + geometry.width + 0.5}%`,
              width: `3%`,
              backgroundColor: '#3284A8',
              padding: '3px',
              borderRadius: '3px',
              color: '#fff'
            }}
          >
            <i class="far fa-clone"></i>
            <i class="far fa-trash-alt"></i>
          </div>
        )} */}
      </>
    );
  };
  return render();
};

function dynamicStyle(props, rectHeight) {
  const { data } = props.annotation;
  const getVerticalAlignStyle = (rectHeight, type) => {
    switch (type) {
      case 'center':
        return `translateY(${(rectHeight - 20) / 2}px)`;
      case 'top':
        return `translateY(2px)`;
      case 'bottom':
        return `translateY(${rectHeight - 20}px)`;
      default:
        return '';
    }
  };

  function parseBIU(data, attribue) {
    if (data === undefined) {
      return false;
    } else {
      return data[attribue] === '1' ? true : false;
    }
  }

  function fontFamily(font) {
    switch (font) {
      case 'courier':
        return 'Courier,monospace';
      case 'helvetica':
        return 'Helvetica,Arial,sans-serif';
      case 'timesNewRoman':
        return 'TimesNewRoman,Times New Roman,Times,Baskerville,Georgia,serif';
      default:
        return 'Helvetica,Arial,sans-serif';
    }
  }

  return {
    width: '100%',
    display: 'flex',
    justifyContent: `${
      data.textAlign === 'right' ? 'flex-end' : data.textAlign
    }`,
    textAlign: `${data.textAlign}`,
    postiton: 'absolute',
    transform: getVerticalAlignStyle(rectHeight, data.verticalAlign),
    // color: data.color ? data.color : '#3284A8',
    color: '#3284A8',
    fontFamily: fontFamily(data.fontFamily),
    // fontSize: `${data.fontSize}px`,
    fontSize: `14px`,
    letterSpacing: `${data.letterSpacing}px`,
    fontWeight: parseBIU(data.boldItalicUnderline, 0) ? 700 : 400,
    fontStyle: parseBIU(data.boldItalicUnderline, 1) ? 'italic' : 'normal',
    textTransform: parseBIU(data.boldItalicUnderline, 2) ? 'uppercase' : 'none'
  };
}

function renderIcon(props) {
  const type = props.annotation.data.type;
  if (type === 'text') {
    return (
      <i
        class="fas fa-font"
        style={{ color: '#3284A8' }}
        aria-hidden="true"
      ></i>
    );
  } else if (type === 'number') {
    return (
      <i
        class="fas fa-hashtag"
        style={{ color: '#3284A8' }}
        aria-hidden="true"
      ></i>
    );
  } else if (type === 'signature') {
    return (
      <i
        class="fas fa-signature"
        style={{ color: '#3284A8' }}
        aria-hidden="true"
      ></i>
    );
  } else if (type === 'checkbox') {
    // return <i class="far fa-check-square" style={{ color: '#3284A8' }} aria-hidden="true"></i>
  } else if (type === 'dateTime') {
    return (
      <i
        class="far fa-calendar-alt"
        style={{ color: '#3284A8' }}
        aria-hidden="true"
      ></i>
    );
  } else if (type === 'image') {
    return (
      <i
        class="far fa-image"
        style={{ color: '#3284A8' }}
        aria-hidden="true"
      ></i>
    );
  }
}

export default Rectangle;
