import React from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { connect, useDispatch, useSelector } from 'react-redux';
import { saveDocumentToServer, setFields } from 'redux/actions';
import {
  getActiveField,
  getCurrentPage,
  getDocument,
  getFields,
  getNumPages
} from 'redux/selectors';
import { DraggableGroup } from './DraggableGroup';
import './FieldsListPanel.scss';
import { reorderObjectsByGroup } from './utils';

const FieldsPanel = (props) => {
  const document = useSelector((state) => getDocument(state, props.documentId));
  const fields = useSelector((state) => getFields(state));
  const dispatch = useDispatch();

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return;
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;

    let newFields = reorderObjectsByGroup([...fields], source, destination);

    dispatch(setFields(newFields));
    dispatch(saveDocumentToServer({ ...document, fields: newFields }, false));
  };

  function renderFields(page) {
    const className =
      page === props.currentPage ? 'page_header active_page' : 'page_header';
    const groupFieldIds =
      fields && fields.length > 0
        ? fields
            .filter((f) => f.data.type === 'group')
            .map((f) => f.data.fields)
            .flat()
            .map((f) => (f ? f.value : ''))
        : [];
    const topLevelFields = fields.filter(
      (f) => !groupFieldIds.includes(f.data.id)
    );
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <div className={className}>
          <Droppable droppableId="droppable_root" type="group">
            {(provided) => (
              <ul
                class="menu-list"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {topLevelFields.map((fieldObj, idx) => {
                  return (
                    <DraggableGroup
                      page={fieldObj.geometry.page}
                      field={fieldObj}
                      fields={fields}
                      index={idx}
                      activeField={props.activeField}
                    />
                  );
                })}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </div>
      </DragDropContext>
    );
  }

  function render() {
    return (
      <div className="fields_panel container">
        <aside class="menu">{renderFields()}</aside>
      </div>
    );
  }
  return render();
};

const mapStateToProps = (state) => ({
  fields: getFields(state),
  numPages: getNumPages(state),
  currentPage: getCurrentPage(state),
  activeField: getActiveField(state)
});

export default connect(mapStateToProps)(FieldsPanel);
