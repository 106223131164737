import React from 'react';

export const AddRuleAction = (props) => {
  return (

    <button type='button'
      class={`conditional_logic button is-small ${props.className}`}
      onClick={props.handleOnClick}>
      <span>{props.label}</span>
    </button>

  );
}
export const RemoveRuleAction = (props) => {
  return (
    <a
      onClick={props.handleOnClick}
      className="remove_rule_action">
      <span><i class="fas fa-times"></i></span>
    </a>
  );
}