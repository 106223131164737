import useOnClickOutside from 'hooks/useOnClickOutside';
import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import DeleteWorkflowModal from './DeleteWorkflowModal';
import DuplicateWorkflowModal from './DuplicateWorkflowModal';

const DropdownButton = ({ data }) => {
  const ref = useRef();
  const [isActive, updateIsActive] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [duplicateModalOpen, setDuplicateModalOpen] = useState(false);

  const dispatch = useDispatch();

  useOnClickOutside(ref, () => {
    updateIsActive(false);
  });

  return (
    <>
      <DeleteWorkflowModal
        workflows={[data]}
        open={modalOpen}
        onClose={(e) => setModalOpen(false)}
      />
      <DuplicateWorkflowModal
        id={data.id}
        name={data.name}
        open={duplicateModalOpen}
        onClose={(e) => setDuplicateModalOpen(false)}
      />

      <div class={`dropdown is-right ${isActive ? 'is-active' : ''}`}>
        <div class="dropdown-trigger">
          <button
            aria-haspopup="true"
            aria-controls="dropdown-menu"
            className="button is-small"
            onClick={(e) => {
              updateIsActive(!isActive);
            }}
          >
            <i class="menu fas fa-ellipsis-h"></i>
          </button>
        </div>

        <div
          class="dropdown-menu"
          style={{ width: `150px` }}
          id="dropdown-menu"
          role="menu"
          ref={ref}
        >
          <div class="dropdown-content">
            <Link to={`/workflows/editor/${data.id}`} class="dropdown-item">
              <i class="far fa-edit"></i> Edit
            </Link>
            <a
              href="#"
              onClick={(e) => {
                setDuplicateModalOpen(true);
                updateIsActive(false);
              }}
              class="dropdown-item"
            >
              <i class="far fa-copy"></i> Duplicate
            </a>
            <a
              href="#"
              onClick={(e) => {
                setModalOpen(true);
                updateIsActive(false);
              }}
              class="dropdown-item delete-item"
            >
              <i class="far fa-trash-alt"></i> Delete
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
export default DropdownButton;
