import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEqual } from 'lodash'

import { getDocument, getFields, isActionPending, changesSynced } from 'redux/selectors';
import { saveDocumentToServer } from 'redux/actions';

const SaveButton = props => {

  const dispatch = useDispatch()

  const pendingSave = useSelector(state => isActionPending(state, 'UPDATE_DOCUMENT'))
  const document = useSelector(state => getDocument(state, props.documentId))
  const fields = useSelector(getFields)
  const synced = useSelector(changesSynced)

  const [initialFields, updateInitial] = useState(fields)

  const disabled = synced && isEqual(initialFields, fields)

  return (
    <div class="field">

      <p class="control">
        <button type='button'
          // disabled={disabled}
          class={`button is-small is-info ${pendingSave && pendingSave.pending && 'is-loading'}`}
          onClick={e => {
            dispatch(saveDocumentToServer({ ...document, fields }))
            updateInitial(fields)
          }}>
          <span class="icon is-small">
            <i class="far fa-save"></i>
          </span>
          <span>Save</span>
        </button>
      </p>
    </div>
  )
}

export default SaveButton;