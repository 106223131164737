import server from 'api/server';
import { upperFirst } from 'lodash';
import * as moment from 'moment';
import React, { useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import Moment from 'react-moment';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser } from 'redux/actions';
import { getUser } from 'redux/selectors';
import '../Settings.scss';

const Billing = (props) => {
  const [loading, setLoading] = useState(false);
  const user = useSelector(getUser);
  const {
    plan,
    status,
    currentPeriodEnd,
    interval,
    cancelledAt,
    paymentMethod,
    subscriptionDetails
  } = user.subscription || {};
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUser());
  }, []);

  const onToken = async (token, plan) => {
    if (paymentMethod && paymentMethod.pmid) {
      // payment method exists, try with that one first
      try {
        await server.post('/v1/subscription/stripe/plan/update', { plan });
        dispatch(fetchUser());
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        // payment method does not exist, create a new one
        await server.post('/v1/subscription/stripe/token/', { token, plan });
        dispatch(fetchUser());
      } catch (error) {
        console.log(error);
      }
    }
  };

  const renderBillingStatus = () => {
    if (!status) return null;
    if (status === 'trialing') {
      const today = moment();
      const end = moment(currentPeriodEnd);
      const remaining = end.diff(today, 'days');
      return (
        <>
          <p>
            This account is on <strong>free trial</strong> for{' '}
            <strong>{plan}</strong> plan. The free trial will end after{' '}
            <strong>{remaining + 1} days</strong>.
          </p>
        </>
      );
    }
    if (status === 'active') {
      const end = moment(currentPeriodEnd).format('MMMM Do YYYY');
      if (
        subscriptionDetails.plan &&
        subscriptionDetails.plan.nickname === 'Lifetime Deal'
      ) {
        return (
          <p>
            This account is on <strong>lifetime </strong>
            {plan} plan.
          </p>
        );
      } else {
        return (
          <p>
            This account is on <strong>{plan}</strong> plan and will renew on{' '}
            <strong>{end}</strong>.
          </p>
        );
      }
    }
    if (status === 'canceled') {
      const cancelled = moment(cancelledAt).format('MMMM Do YYYY');

      if (moment().isAfter(cancelledAt)) {
        return (
          <p>
            This account was on <strong>{plan}</strong> plan and was canceled on{' '}
            <strong>{cancelled}</strong>.
          </p>
        );
      } else {
        return (
          <p>
            This account is on <strong>{plan}</strong> plan and will be canceled
            on <strong>{cancelled}</strong>.
          </p>
        );
      }
    }
  };

  const renderPaymentMethod = () => {
    if (paymentMethod) {
      return (
        <p>
          {upperFirst(paymentMethod.brand)} ending with {paymentMethod.last4}
        </p>
      );
    } else if (status === 'trialing' && !paymentMethod) {
      return (
        <p>
          Please provide your payment method to continue this plan after free
          trial ends.
        </p>
      );
    } else {
      return <p>No payment method.</p>;
    }
  };

  const renderPortalButton = (
    buttonText,
    classes,
    disabled = false,
    suffix
  ) => {
    return (
      <button
        onClick={async () => {
          try {
            const response = await server.post('subscription/stripe/portal');

            window.location.href = `${response.data.url}${
              suffix ? suffix : ''
            }`;
          } catch (error) {
            console.log(error);
          }
        }}
        disabled={disabled}
        type="submit"
        className={`button billing_portal ${classes}`}
      >
        {buttonText}
      </button>
    );
  };

  const renderPlanButton = (planName) => {
    const order = { essential: 1, professional: 2, business: 3 };

    let label;
    if (plan === planName) {
      label = 'Current plan';
    } else if (status === 'canceled') {
      label = 'Choose';
    } else if (order[planName] < order[plan]) label = 'Downgrade';
    else label = 'Upgrade';

    return renderPortalButton(
      label,
      `${(label === 'Upgrade' || label === 'Choose') && 'is-info'}`,
      label === 'Current plan',
      subscriptionDetails && subscriptionDetails.subid
        ? `/subscriptions/${subscriptionDetails.subid}/update`
        : ''
    );
  };

  const renderPlans = () => {
    return (
      <div className="plans">
        <div class="columns">
          {/* <div class="column">
            <p>Essential ($29/mo)</p>
            {renderPlanButton('essential')}
            <hr />
            <ul>
              <li>50 Generated PDFs / month</li>
              <li>250 Workflow Actions / month</li>
              <li>Standard Support</li>
            </ul>
          </div> */}
          <div class="column">
            <p>Professional</p>
            {renderPlanButton('professional')}
            <hr />
            <ul>
              <li>1000 Generated PDFs / month</li>
            </ul>
          </div>
          <div class="column">
            <p>Business</p>
            {renderPlanButton('business')}
            <hr />
            <ul>
              <li>5000 Generated PDFs / month</li>
            </ul>
          </div>
          <div class="column">
            <p>Enterprise</p>
            <a href="mailto:contact@docdown.io" class="button is-info">
              Contact Us
            </a>
            <hr />
            <ul>
              <li>50000 Generated PDFs / month</li>
            </ul>
          </div>
        </div>
      </div>
    );
  };
  const renderLoading = () => {
    return (
      <div className="billing_loader_container">
        <Loader type="Oval" color="#ccc" height={30} width={30} />
      </div>
    );
  };
  const renderUsage = () => {
    //console.log(user);
    const {
      usage: {
        usageData: { usage, quota, currentWindowEnd }
      }
    } = user;
    const documentPercent =
      usage.generated_documents / quota.generated_documents;
    return (
      <>
        <div className="level is-mobile">
          <div className="level-left">
            <div className="level-item">
              {`${usage.generated_documents} / ${quota.generated_documents}`}
            </div>
          </div>
          <div className="level-right">
            <div className="level-item">
              <span>
                reset scheduled <Moment format="lll">{currentWindowEnd}</Moment>
              </span>
            </div>
          </div>
        </div>
        <progress
          class="progress is-info is-small"
          value={usage.generated_documents}
          max={quota.generated_documents}
        >
          {`${documentPercent || 0}%`}
        </progress>
      </>
    );
  };
  const renderContent = () => {
    return (
      <div>
        <p>
          <strong>Billing Status</strong>
        </p>
        {renderBillingStatus()}
        <hr />
        <p>
          <strong>Payment Method</strong>
        </p>
        <div className="level is-mobile">
          <div className="level-left">
            <div className="level-item">{renderPaymentMethod()}</div>
          </div>
          <div className="level-right">
            <div className="level-item">
              {renderPortalButton(
                paymentMethod ? 'Update Payment Method' : 'Add Payment Method',
                '',
                false,
                '/payment-methods'
              )}
            </div>
          </div>
        </div>
        <hr />
        <p>
          <strong>Current usage</strong>
        </p>
        {renderUsage()}
        <hr />
        <div>
          <p>
            <strong>Subscription Plans</strong>
          </p>
          {renderPlans()}
        </div>
      </div>
    );
  };
  return (
    <div className="billing">
      {user && user.subscription ? renderContent() : renderLoading()}
    </div>
  );
};

export default Billing;
