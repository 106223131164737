import React, { useState } from 'react';
import './History.scss';
export const DropdownFilter = ({ options, defaultValueIndex, onFilter }) => {
  const [value, setValue] = useState(options[defaultValueIndex]);
  return (
    <div class="select dropdown_filter">
      <select
        value={value}
        // className={''}
        onChange={(e) => {
          setValue(e.target.value);
          onFilter(e.target.value);
        }}
      >
        {options.map((o) => (
          <option value={o}>{o}</option>
        ))}
      </select>
    </div>
  );
};
