import server from 'api/server';
import Page from 'components/Page';
import CollapsibleCard from 'components/_common/CollapsibleCard';
import DownloadButton from 'components/_common/DownloadButton';
import { lowerCase, startCase } from 'lodash';
import * as moment from 'moment';
import React, { useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import Moment from 'react-moment';
import { toast } from 'react-toastify';
import { getStatusIcon } from './utils';

const DATEFORMAT = 'MMMM Do, YYYY HH:mm:ss (ZZ)';

const ReminderButton = (props) => {
  const [loading, setLoading] = useState(false);
  const sendWaitingEmail = async () => {
    try {
      setLoading(true);
      await server.post('/email/intermediate', { generatedDocId: props.id });
      setLoading(false);
      toast.info('Reminder email sent');
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <button
      className={`button is-small is-info ${loading ? 'is-loading' : ''}`}
      onClick={sendWaitingEmail}
    >
      <span className="icon">
        <i className="fas fa-paper-plane" />
      </span>
      <span>Send Reminder</span>
    </button>
  );
};

const CancelButton = (props) => {
  const [loading, setLoading] = useState(false);
  const onClick = async () => {
    if (window.confirm('Are your sure you want to cancel the document?')) {
      try {
        setLoading(true);
        await server.post('/cancel/workflow', {
          workflowRunId: props.workflowRunId
        });
        toast.info('Status updated to canceled');
        await new Promise((r) => setTimeout(r, 2000));
        setLoading(false);
        window.location.reload();
      } catch (err) {
        console.log(err);
      }
    }
  };
  return (
    <button
      className={`button is-small is-info ${loading ? 'is-loading' : ''}`}
      onClick={onClick}
    >
      <span className="icon">
        <i className="fas fa-ban" />
      </span>
      <span>Cancel</span>
    </button>
  );
};

const DocumentDetails = ({ data }) => {
  const { status, sha256, sha256Log, updatedAt, log } = data;
  const [logData, setLogData] = useState([]);
  const processLogs = (log) => {
    const sessions = [];
    if (!log || log.length === 0) return sessions;
    for (let i = 0; i < log.length; i++) {
      const { ip, userAgent, url, startDateTime, submitDateTime, fields } = log[
        i
      ];
      // Separate out data fields and signature field
      const dataFields = [];
      const signatures = [];
      Object.keys(fields).forEach((f) =>
        fields[f].type === 'signature'
          ? signatures.push({ ...fields[f], fieldName: f })
          : dataFields.push({ ...fields[f], fieldName: f })
      );
      // Add meta tracking information
      const meta = {
        ip,
        userAgent,
        startDateTime: moment(startDateTime).format(DATEFORMAT),
        submitDateTime: moment(submitDateTime).format(DATEFORMAT),
        url
      };
      sessions.push({ meta, dataFields, signatures });
    }
    return sessions;
  };
  useEffect(() => {
    setLogData(processLogs(log));
  }, []);

  const renderMeta = (meta) => {
    return (
      <>
        {meta.ip && (
          <tr>
            <td>IP</td> <td>{meta.ip}</td>
          </tr>
        )}
        {meta.url && (
          <tr>
            <td>URL</td> <td>{meta.url}</td>
          </tr>
        )}

        {meta.userAgent && (
          <tr>
            <td>User agent</td> <td>{meta.userAgent}</td>
          </tr>
        )}
        <tr>
          <td>Started</td>
          <td>{meta.startDateTime}</td>
        </tr>
        <tr>
          <td>Submitted</td> <td>{meta.submitDateTime}</td>
        </tr>
      </>
    );
  };

  const renderFields = (fields) => {
    return (
      <tr>
        <td>Fields</td>
        <td>
          <table className="table">
            <tr className="field-headers">
              <td>Name</td>
              <td>Value</td>
              <td>Updated At</td>
            </tr>
            {fields.map((f) => {
              return (
                <tr className="fieldrow">
                  <td>{f.fieldName}</td>
                  <td>
                    {typeof f.value === 'object' ? (
                      f.value.base64 ? (
                        <img src={f.value.base64} class="value_image" />
                      ) : (
                        ''
                      )
                    ) : f.value &&
                      f.value.includes &&
                      f.value.includes('data:image') ? (
                      <img src={f.value} class="value_image" />
                    ) : (
                      f.value
                    )}
                  </td>
                  <td>
                    <Moment format={DATEFORMAT}>{f.updated}</Moment>
                  </td>
                </tr>
              );
            })}
          </table>
        </td>
      </tr>
    );
  };
  const renderSignatures = (signatures) => {
    return (
      <>
        {signatures.map((sig, i) => (
          <tr className="signatures" key={i}>
            <td>Signature</td>
            <td>
              {sig.value.signerName && sig.value.signerEmail && (
                <span>
                  Signed by {sig.value.signerName} ({sig.value.signerEmail}){' '}
                </span>
              )}{' '}
              <br />
              <img src={sig.value.signature} class="signature_image" />
              <br />
              Signed at <Moment format={DATEFORMAT}>{sig.updated}</Moment>.
            </td>
          </tr>
        ))}
      </>
    );
  };
  return (
    <div className="box">
      <h5 className="title document-history-title">Document History</h5>
      {!log || (logData.length === 0 && <p>Not available</p>)}
      {logData &&
        logData.map(({ meta, dataFields, signatures }) => {
          return (
            <>
              <table className="document-history">
                {renderMeta(meta)}
                {renderFields(dataFields)}
                {renderSignatures(signatures)}
              </table>
              <hr />
            </>
          );
        })}
    </div>
  );
};

const Document = (props) => {
  const { generatedDocId } = props.match.params;
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchDoc = async (generatedDocId) => {
      try {
        const { data } = await server.get(`/workflows/doc/${generatedDocId}`);
        setData(data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchDoc(generatedDocId);
  }, [generatedDocId]);

  const renderLoading = () => {
    return (
      <div className="loader_container">
        <Loader type="Oval" color="#ccc" height={30} width={30} />
      </div>
    );
  };
  const getUpdatedAtLabel = (status) => {
    switch (status) {
      case 'COMPLETED':
        return 'Completed At';
      case 'CANCELED':
        return 'Canceled At';
      default:
        return 'Last Updated';
    }
  };

  const render = () => {
    return (
      <div className="history_run document_page">
        {/* <section className="section">
          <div className="container">
            <nav className="level">
              <div className="level-left">
                <div className="level-item">
                  <h4 className="title is-4">
                    
                  </h4>
                </div>
              </div>
            </nav>
          </div>
        </section> */}
        <div className="container">
          <table className="document-info">
            <tr>
              <td>Status</td>
              <td>
                {getStatusIcon(data.status)}{' '}
                <span className="status">
                  {startCase(lowerCase(data.status))}
                </span>
              </td>
            </tr>
            <tr>
              <td>Document ID</td>
              <td>{data.id}</td>
            </tr>
            <tr>
              <td>{getUpdatedAtLabel(data.status)}</td>
              <td>
                <Moment format={DATEFORMAT}>{data.updatedAt}</Moment>
              </td>
            </tr>
            {data.status === 'WAITING' &&
              data.meta.waiting &&
              data.meta.waiting.email && (
                <tr>
                  <td>Waiting For</td>
                  <td>{data.meta.waiting.email}</td>
                </tr>
              )}
          </table>
          <DocumentDetails data={data} />
          {data.sha256 && (
            <CollapsibleCard id={data.id} title="SHA256 Security Hashes">
              <strong>Document</strong> <pre>{data.sha256}</pre>
              <br />
              <strong>Audit Trail</strong> <pre>{data.sha256Log}</pre>
            </CollapsibleCard>
          )}
        </div>
      </div>
    );
  };

  return (
    <Page
      title={
        data
          ? `${data.fileName}${data.type === 'PDF' ? '.pdf' : ''}`
          : 'Document'
      }
      action={
        data && (
          <>
            {data.fileName && data.path && (
              <div className="nav-item margin-right-5">
                {data.status === 'COMPLETED' && (
                  <DownloadButton
                    url={data.path}
                    extension={`${data.type === 'PDF' ? 'pdf' : ''}`}
                    classes="button upload is-small is-info"
                    fileName={data.fileName || data.documentName}
                  >
                    <span className="icon">
                      <i className="fas fa-download" />
                    </span>
                    <span>Document</span>
                  </DownloadButton>
                )}
              </div>
            )}
            <div className="nav-item">
              {data.fileName && data.auditTrailPath && (
                <DownloadButton
                  url={data.auditTrailPath}
                  extension={`${data.type === 'PDF' ? 'pdf' : ''}`}
                  classes="button upload is-small is-info"
                  fileName={`${data.fileName || data.documentName}_audit_trail`}
                >
                  <span className="icon">
                    <i className="fas fa-download" />
                  </span>
                  <span>Audit Trail</span>
                </DownloadButton>
              )}
            </div>
            {data.status === 'WAITING' && (
              <div className="nav-item margin-right-5">
                <ReminderButton id={data.id} />
              </div>
            )}
            {data.status === 'WAITING' && data.workflowRunId && (
              <div className="nav-item">
                <CancelButton workflowRunId={data.workflowRunId} />
              </div>
            )}
          </>
        )
      }
    >
      <div className="history_run_container document_details">
        {data ? render() : renderLoading()}
      </div>
      {/* <Footer /> */}
    </Page>
  );
};
export default Document;
