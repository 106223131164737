import useOnClickOutside from 'hooks/useOnClickOutside';
import React, { useRef, useState } from 'react';
import './EllipsisDropdown.scss';

const EllipsisDropdown = ({ items, width }) => {
  const ref = useRef();
  const [isActive, updateIsActive] = useState(false);
  const [loading, setLoading] = useState(false);

  useOnClickOutside(ref, () => {
    updateIsActive(false);
  });
  return (
    <div
      class={`ellipsis_dropdown dropdown is-right ${
        isActive ? 'is-active' : ''
      }`}
    >
      <div class="dropdown-trigger">
        <button
          aria-haspopup="true"
          aria-controls="dropdown-menu"
          className={`button is-small ${loading ? 'is-loading' : ''}`}
          onClick={() => updateIsActive(!isActive)}
        >
          <i class="menu fas fa-ellipsis-h"></i>
        </button>
      </div>

      <div
        class="dropdown-menu"
        style={{ minWidth: `${width || 100}px` }}
        id="dropdown-menu"
        role="menu"
        ref={ref}
      >
        <div class="dropdown-content" style={{ fontSize: '14px' }}>
          {items.map(({ title, onClick, classes, icon, show }, index) =>
            show ? (
              <div
                id={`ellipsis-dropdown-button-open-menu-${index}`}
                onClick={async (e) => {
                  e.preventDefault();
                  updateIsActive(false);
                  setLoading(true);
                  await onClick();
                  setLoading(false);
                }}
                class={`dropdown-item ${classes}`}
              >
                <span style={{ fontSize: '12px' }}>
                  <i class={icon} /> {title}
                </span>
              </div>
            ) : null
          )}
        </div>
      </div>
    </div>
  );
};
export default EllipsisDropdown;
