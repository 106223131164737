import { startCase } from 'lodash';
import React from 'react';

const ObjectButton = ({ object }) => {
  const truncate = (input) =>
    input.length > 30 ? `${input.substring(0, 27)}...` : input;

  if (object.objectType === 'document') {
    return (
      <span class="object_type">
        <a
          href={object.document ? `/documents/editor/${object.objectId}` : '#'}
          className={`object_link ${object.document ? '' : 'disabled'}`}
          id={`ActivityLog-DocumentLink-Button-${object.objectId}`}
        >
          <i className="fas fa-file"></i>
          {object.document
            ? truncate(object.document.name)
            : 'Unavailable Document'}
          {object.document && <i className="fas fa-external-link-alt"></i>}
        </a>
      </span>
    );
  } else if (object.objectType === 'workflow') {
    return (
      <span class="object_type">
        <a
          href={object.workflow ? `/workflows/editor/${object.objectId}` : '#'}
          className={`object_link ${object.workflow ? '' : 'disabled'}`}
          id={`ActivityLog-WorkflowLink-Button-${object.objectId}`}
        >
          <i className="fas fa-stream"></i>{' '}
          {object.workflow
            ? truncate(object.workflow.name)
            : 'Unavailable Workflow'}
          {object.workflow && <i className="fas fa-external-link-alt"></i>}
        </a>
      </span>
    );
  } else if (object.objectType === 'user') {
    return (
      <span class="object_type">
        <a
          href={object.userObject ? `/settings/members` : '#'}
          className={`object_link ${object.userObject ? '' : 'disabled'}`}
          id={`ActivityLog-UserLink-Button-${object.objectId}`}
        >
          <i className="fas fa-user"></i>{' '}
          {object.userObject
            ? truncate(object.userObject.name)
            : 'Unavailable User'}
          {object.userObject && <i className="fas fa-external-link-alt"></i>}
        </a>
      </span>
    );
  } else if (object.objectType === 'generated_document') {
    return (
      <span class="object_type">
        <a
          href={
            object.generatedDocument
              ? `/history/document/${object.objectId}`
              : '#'
          }
          className={`object_link ${
            object.generatedDocument ? '' : 'disabled'
          }`}
          id={`ActivityLog-GeneratedDocumentLink-Button-${object.objectId}`}
        >
          <i className="fas fa-file-pdf"></i>{' '}
          {object.generatedDocument
            ? truncate(`${object.generatedDocument.fileName}.pdf`)
            : 'Unavailable PDF'}
          {object.generatedDocument && (
            <i className="fas fa-external-link-alt"></i>
          )}
        </a>
      </span>
    );
  } else {
    return <span class="object_type">{startCase(object.objectType)}</span>;
  }
};

export default ObjectButton;
