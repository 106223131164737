import tooltips from 'components/DocumentEditor/tooltips';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { updateFieldName } from 'redux/actions';
import {
  BIUButtons,
  Checkbox,
  ColorPicker,
  Conditional,
  DeleteField,
  Dropdown,
  FontPicker,
  HorizontalAlignment,
  Slider,
  TextField,
  VerticalAlignment
} from './components';
import FieldTypeDropdown from './components/FieldTypeDropdown';

const DateTimeField = (props) => {
  const values = useSelector((state) => getFormValues(props.form)(state));
  const dispatch = useDispatch();

  useEffect(() => {
    props.onChange(values);
  }, [values]);

  const fieldNames = [
    ...props.fields.map((f) => f.data.name),
    'generatedDocument'
  ];
  useEffect(() => {
    if (props.initialValues.name !== values.name) {
      let nameMap = {};
      nameMap[props.initialValues.name] = values.name;
      dispatch(updateFieldName(nameMap));
    }
  }, [values.name]);
  function renderGeneral() {
    return (
      <React.Fragment>
        <FieldTypeDropdown
          type={props.field.data.type}
          onFieldChange={props.onFieldChange}
        />
        <Field
          component={TextField}
          alphaNumeric
          required
          tooltip={tooltips.name}
          // illegal={fieldNames}
          max={64}
          label="Name"
          name="name"
          type="text"
          placeholder="Field Name"
        />
        <Field
          label="Label"
          component={TextField}
          name="label"
          tooltip={tooltips.label}
          type="text"
          placeholder="Form Label"
        />
        <Field
          label="Description"
          component={TextField}
          tooltip={tooltips.description}
          name="description"
          type="text"
          placeholder="Form Description"
        />
        <Field
          name="dateTimeFormat"
          label="Format"
          component={Dropdown}
          tooltip={tooltips['format-date']}
          options={[
            { value: 'MM/DD/YYYY', label: 'MM/DD/YYYY' },
            { value: 'DD/MM/YYYY', label: 'DD/MM/YYYY' },
            { value: 'YYYY-MM-DD', label: 'YYYY-MM-DD' },
            { value: 'dddd, MMMM Do YYYY', label: 'Day, Month Dth YYYY' },
            { value: 'custom', label: 'Custom Format' }
          ]}
        />
        {values && values.dateTimeFormat === 'custom' && (
          <Field
            label="Custom Format"
            component={TextField}
            // tooltip={tooltips.customFormat}
            doc={{
              url: 'https://help.docdown.io/en/articles/4885349-custom-date-formats',
              label: 'Learn custom format syntax'
            }}
            name="customFormat"
            type="text"
            placeholder=""
          />
        )}

        <Field
          label="Required"
          component={Checkbox}
          name="required"
          tooltip={tooltips.required}
        />
        <Field
          label="Hide in Document"
          component={Checkbox}
          name="hidden"
          tooltip={tooltips.hidden}
        />
        <Field
          label="Hide in Form"
          component={Checkbox}
          name="hiddenForm"
          tooltip={tooltips.hiddenForm}
        />
        <Field
          label="Static"
          component={Checkbox}
          name="static"
          tooltip={tooltips.static}
        />
        <div class="is-divider" data-content="APPEARANCE"></div>
        <Field
          label="Font"
          options={[
            { value: 'courier', label: 'Courier' },
            { value: 'helvetica', label: 'Helvetica' },
            { value: 'timesNewRoman', label: 'Times New Roman' }
          ]}
          component={FontPicker}
          name="fontFamily"
        />
        <Field
          label="Size"
          component={TextField}
          name="fontSize"
          type="number"
          step="1"
          placeholder="Font Size"
        />
        <Field
          label="Overflow"
          options={[
            { value: 'flow', label: 'Flow' },
            { value: 'wrap', label: 'Wrap' }
          ]}
          component={Dropdown}
          name="overflow"
        />
        <Field component={BIUButtons} name="boldItalicUnderline" />
        <Field component={HorizontalAlignment} name="textAlign" />
        <Field component={VerticalAlignment} name="verticalAlign" />

        <Field component={ColorPicker} name="color" />
        <div class="is-divider" data-content="POSITION"></div>
        <Field
          component={Slider}
          step={0.1}
          min={0}
          max={100}
          name="x"
          label="X"
        />
        <Field
          component={Slider}
          step={0.1}
          min={0}
          max={100}
          name="y"
          label="Y"
        />
        <Field
          component={Slider}
          step={0.1}
          min={0}
          max={100}
          name="width"
          label="Width"
        />
        <Field
          component={Slider}
          step={0.1}
          min={0}
          max={100}
          name="height"
          label="Height"
        />

        <div class="is-divider"></div>
        <DeleteField onDelete={props.onDelete} />
      </React.Fragment>
    );
  }
  function renderAdvanced() {
    return (
      <React.Fragment>
        <Field
          component={Conditional}
          tooltip={tooltips['condition-formula']}
          field={props.field}
          fields={props.fields}
          name="conditionalFormula"
        />
      </React.Fragment>
    );
  }
  function render() {
    return (
      <div className="text_field_editor" onClick={props.onSelect}>
        <form
          onKeyPress={(e) => {
            e.key === 'Enter' && e.preventDefault();
          }}
        >
          {props.view === 'data' ? renderGeneral() : renderAdvanced()}
        </form>
      </div>
    );
  }
  return render();
};

export default reduxForm()(DateTimeField);
