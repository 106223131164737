import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

const DownloadButton = (props) => {
  const { classes, url, fileName, extension, disabled } = props;
  const [loading, setLoading] = useState(false);
  const download = () => {
    setLoading(true);
    axios({
      method: 'get',
      url,
      responseType: 'arraybuffer'
    })
      .then((response) => {
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(
          new Blob([response.data], { type: 'application/octet-stream' })
        );
        link.download = `${fileName}.${extension}`;

        document.body.appendChild(link);

        link.click();
        setTimeout(function () {
          window.URL.revokeObjectURL(link);
        }, 200);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        toast.info('Could not download the document');
      });
  };
  return props.type === 'a' ? (
    <a class={`${classes} ${loading ? 'is-loading' : ''}`} onClick={download}>
      {props.children || 'Download'}
    </a>
  ) : (
    <button
      class={`${classes} ${loading ? 'is-loading' : ''}`}
      onClick={download}
      disabled={disabled}
    >
      {props.children || 'Download'}
    </button>
  );
};
export default DownloadButton;
