import server from 'api/server';
import React, { useState } from 'react';
import Modal from 'react-responsive-modal';
import { toast } from 'react-toastify';

const AddModal = ({ open, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const onSubmit = async () => {
    try {
      setLoading(true);
      const { data } = await server.post('apps/email', { email });
      setLoading(false);
      if (data.id) window.location = `/integrations/${data.id}`;
    } catch (err) {
      setLoading(false);
      onClose();
      if (err.response && err.response.data && err.response.data.message) {
        console.log(err.response.data.message);
      }
      toast.info('Could not add email address!');
      console.log(err);
    }
  };
  return (
    <Modal open={open} onClose={onClose} closeOnOverlayClick={false}>
      <div class="modal_top">
        <h3 class="title is-h3">Add Custom Email</h3>
        <p>Enter an address from the domain you want to send emails from.</p>
        <div class="field">
          <label class="label">Email</label>
          <div class="control">
            <input
              class="input is-small"
              type="email"
              placeholder="Email address..."
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
        <hr />
        <div class="field is-grouped">
          <div class="control">
            <button
              onClick={onSubmit}
              class={`button is-info is-small ${loading ? 'is-loading' : ''}`}
            >
              Confirm
            </button>
          </div>
          <div class="control">
            <button onClick={onClose} class="button is-info is-small is-light">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default AddModal;
