import CollapsibleCard from 'components/_common/CollapsibleCard';
import TextField from 'components/_common/FormFields/TextField';
import useDimensions from 'hooks/useDimensions';
import React, { useCallback, useEffect, useState } from 'react';
import EditableLabel from 'react-inline-editing';
import PasswordStrengthBar from 'react-password-strength-bar';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  Field,
  getFormInitialValues,
  getFormValues,
  isValid,
  reduxForm
} from 'redux-form';
import { required, url } from 'redux-form-validators';
import { fetchDocuments } from 'redux/actions';
import { saveStepToServer, updateStep } from 'redux/actions/workflowActions';
import { getDocuments, isActionPending } from 'redux/selectors';
import sanitizeFilename from 'sanitize-filename';
import { validFileName } from 'utils/validators';
import { FieldSelect, ReSelect } from '../components/Select';
import TinyEditor from '../components/TinyEditor';
import useExposedFields from './useExposedFields';

/**
 * Settings component for DocumentForm workflow step type.
 * 1. Defines local state and reference gloabal state using hooks.
 * 2. Defines other hooks.
 * 3. Define side effects using useEffect hooks. Initializes from
 * `props.initialValues` and fetches documents to display in the settings.
 * 4. Define submit function that saves stepData to server.
 * 5. Define Topbar component of the settings pane.
 * 6. Finally Render the settings form.
 */
const DocumentSettingsComponent = (props) => {
  const step = props.step;

  // ##########################################################################
  // 1. Define local state and reference gloabal state using hooks.
  // ##########################################################################

  const [name, setName] = useState(step.name || props.defaultName);
  const [currentTab, setTab] = useState('settings');

  const documents = useSelector(getDocuments);
  const [parentDocument, setParentDocument] = useState({ fields: [] });
  const valid = useSelector((state) => isValid(props.form)(state));

  const pendingUpdate = useSelector((state) =>
    isActionPending(state, 'UPDATE_STEP')
  );
  const currentValues = useSelector((state) =>
    getFormValues(props.form)(state)
  );
  const initialValues = useSelector((state) =>
    getFormInitialValues(props.form)(state)
  );

  // ##########################################################################
  // 2. Define other hooks.
  // ##########################################################################

  const dispatch = useDispatch();
  const [ref, { x }] = useDimensions(true);

  // ##########################################################################
  // 3. Define side effects using useEffect hooks.
  // ##########################################################################

  useEffect(() => {
    props.initialize(props.initialValues);
    dispatch(fetchDocuments());
  }, []);

  // Get the document object so we can generate dynamic fields.
  useEffect(() => {
    if (documents && currentValues && currentValues['selectedDocument']) {
      const doc = documents.find(
        (d) => d.id === currentValues['selectedDocument'].value
      );
      if (doc) setParentDocument(doc);
    }
  }, [documents, currentValues]);

  const prevData = useExposedFields(step.order, parentDocument, currentValues);

  // Autofill the generated file name based on selected document name.
  // When the user selects different document, only updates the file name
  // if it is not the autofilled value.

  const fileNameState = useCallback(() => {
    const selectedFileName = currentValues['fileName'];

    const currentFileName = sanitizeFilename(
      currentValues['selectedDocument'].label
    ).replace('.pdf', '');

    const previousFileName = initialValues['selectedDocument']
      ? sanitizeFilename(initialValues['selectedDocument'].label).replace(
          '.pdf',
          ''
        )
      : selectedFileName;

    return {
      selectedFileName,
      currentFileName,
      previousFileName
    };
  }, [currentValues, initialValues]);

  useEffect(() => {
    if (currentValues && currentValues['selectedDocument'] && initialValues) {
      const { selectedFileName, currentFileName, previousFileName } =
        fileNameState();

      if (
        !selectedFileName ||
        selectedFileName.length === 0 ||
        selectedFileName === previousFileName ||
        selectedFileName !== currentFileName
      ) {
        props.autofill('fileName', currentFileName);
      }
    }
  }, [currentValues['selectedDocument']]);

  // Revert back to Display all Fields if user selects a different document.
  useEffect(() => {
    if (currentValues && currentValues['selectedDocument'] && initialValues) {
      const { currentFileName, previousFileName } = fileNameState();

      if (currentFileName !== previousFileName) {
        props.autofill('displayFields', {
          value: 'all',
          label: 'Display all fields'
        });
        props.autofill('selectedFields', []);
      }
    }
  }, [currentValues['selectedDocument']]);

  // ##########################################################################
  // 4. Define submit function that saves stepData to server.
  // ##########################################################################

  function submit(values) {
    function createUpdatedStep(values) {
      const {
        selectedDocument,
        fileName,
        download,
        access,
        formPassword,
        title,
        description,
        selectedFields,
        displayFields,
        displayDocument,
        submissionMessage,
        submitLabel,
        requiredLabel,
        submissionRedirect
      } = values;
      const document = documents.find((d) => d.id === selectedDocument.value);
      const newStep = {
        ...step,
        name,
        data: {
          ...step.data,
          valid,
          stepData: {
            fileName,
            downloadFile: download ? download.value : false,
            title,
            description,
            access,
            documentId: document.id,
            documentName: document.name,
            selectedFields,
            displayFields,
            displayDocument,
            submissionMessage,
            submitLabel,
            requiredLabel,
            submissionRedirect
          },
          stepSettings: {
            fileName,
            access,
            download,
            title,
            description,
            formPassword,
            selectedDocument: { value: document.id, label: document.name },
            selectedFields,
            displayFields,
            displayDocument,
            submissionMessage,
            submitLabel,
            requiredLabel,
            submissionRedirect
          },
          exposedFields: [
            ...prevData[step.order],
            { type: 'attachment', name: 'generatedDocument' }
          ]
        }
      };
      return newStep;
    }

    const newStep = createUpdatedStep(values);
    dispatch(updateStep(newStep));
    dispatch(saveStepToServer(newStep));
  }

  // ##########################################################################
  // 5. Define Topbar component of the settings pane.
  // ##########################################################################
  const Topbar = ({ name, setName }) => {
    return (
      <div className="step_bar">
        <nav class="level">
          <div class="level-left step-editable-name">
            <div class="level-item">
              <span className="step_name" title="Click to edit name">
                <Field
                  name="stepName"
                  value={name}
                  component={(props) => (
                    <>
                      <EditableLabel
                        onFocusOut={(text) => {
                          setName(text);
                          props.input.onChange(name);
                        }}
                        inputClassName="input"
                        text={name}
                      />
                    </>
                  )}
                />
              </span>
            </div>
            <div class="level-item">
              <i class="fa fa-edit" />
            </div>
          </div>
          <div class="level-right">
            <button
              type="submit"
              disabled={!documents || pristine}
              className={`button is-small is-info ${
                pendingUpdate && pendingUpdate.pending ? 'is-loading' : ''
              }`}
            >
              Save
            </button>
          </div>
        </nav>
      </div>
    );
  };

  // ##########################################################################
  // 6. Render the settings form.
  // ##########################################################################

  let formFields = [];
  if (currentValues && currentValues.selectedDocument) {
    const doc = documents.find(
      (d) => d.id === currentValues.selectedDocument.value
    );

    if (doc) {
      doc.fields.forEach((f) => {
        formFields.push({
          value: f.data.name,
          label: f.data.label || f.data.name
        });
      });
    }
  }
  const getFormURL = (id) => {
    return `${process.env.REACT_APP_BASE_URL}/trigger/form/${id}`;
  };
  const { handleSubmit, pristine } = props;
  return (
    <div className="step_setting" ref={ref}>
      <form onSubmit={handleSubmit(submit)}>
        <Topbar name={name} setName={setName} />
        <div className="fields">
          <div class="tabs is-fullwidth">
            <ul>
              <li class={currentTab === 'settings' ? 'is-active' : ''}>
                <a onClick={() => setTab('settings')}>
                  <span class="icon is-small">
                    <i class="fas fa-cog" aria-hidden="true"></i>
                  </span>
                  <span>Document and Fields</span>
                </a>
              </li>
              <li class={currentTab === 'appearance' ? 'is-active' : ''}>
                <a onClick={() => setTab('appearance')}>
                  <span class="icon is-small">
                    <i class="fas fa-image" aria-hidden="true"></i>
                  </span>
                  <span>Access and Appearance</span>
                </a>
              </li>
              <li class={currentTab === 'submission' ? 'is-active' : ''}>
                <a onClick={() => setTab('submission')}>
                  <span class="icon is-small">
                    <i class="fas fa-save" aria-hidden="true"></i>
                  </span>
                  <span>Form Submission</span>
                </a>
              </li>
            </ul>
          </div>

          <div style={currentTab === 'settings' ? {} : { display: 'none' }}>
            <label class="label margin-top-5 margin-right-5">Form Link</label>
            <div class="field has-addons">
              <p class="control is-expanded">
                <input
                  disabled
                  value={getFormURL(step.id)}
                  className="input is-small"
                ></input>
              </p>
              <p class="control">
                <a
                  class="button copy_button"
                  onClick={() => {
                    navigator.clipboard.writeText(getFormURL(step.id));
                    toast.info('Link copied to clipboard');
                  }}
                >
                  <i class="far fa-copy"></i>
                </a>
                <a
                  class="button link_button"
                  href={`/trigger/form/${step.id}`}
                  target="_blank"
                >
                  <i class="fas fa-external-link-alt"></i>
                </a>
              </p>
            </div>
            <p class="help pull-up">
              If the workflow is inactive, submissions will be disabled.
            </p>
            <div class="field">
              <label class="label">
                Document <span className="has-text-grey">(required)</span>
              </label>
              <div class="control has-icons-left has-icons-right">
                <Field
                  // onChange={e => { currentValues && submit(currentValues) }}
                  name="selectedDocument"
                  component={ReSelect}
                  disabled={!documents ? true : false}
                  options={documents.map((d, i) => ({
                    value: d.id,
                    label: d.name
                  }))}
                  validate={[required({ msg: 'Please select a document.' })]}
                />
              </div>
              <p class="help">Select document to generate form fields.</p>
            </div>

            {formFields && (
              <div class="field">
                <label class="label">Fields</label>
                <div class="control">
                  <Field
                    name="displayFields"
                    component={ReSelect}
                    options={[
                      { value: 'all', label: 'Display all fields' },
                      { value: 'selected', label: 'Display selected fields' }
                    ]}
                    validate={[]}
                  />
                  {currentValues &&
                    currentValues.displayFields &&
                    currentValues.displayFields.value === 'selected' && (
                      <>
                        <label class="label">Select fields</label>
                        <Field
                          name="selectedFields"
                          component={ReSelect}
                          options={formFields}
                          multi
                        />
                      </>
                    )}
                </div>
                <p class="help">Select which fields to show in this form.</p>
              </div>
            )}
          </div>

          <div style={currentTab === 'appearance' ? {} : { display: 'none' }}>
            <div class="field">
              <div class="control">
                <label class="label">Who can access the form? </label>
                <Field
                  name="access"
                  component={ReSelect}
                  options={[
                    { value: 'public', label: 'Anyone with the form link' },
                    {
                      value: 'auth',
                      label: 'Use a password based authentication'
                    }
                  ]}
                  validate={[]}
                />
              </div>
            </div>
            {currentValues &&
              currentValues.access &&
              currentValues.access.value === 'auth' && (
                <div class="field">
                  <div class="control">
                    <label class="label">
                      Form Password{' '}
                      <span className="has-text-grey">(required)</span>
                    </label>
                    <Field
                      name="formPassword"
                      component={TextField}
                      validate={[
                        required({
                          msg: 'Please specify a password for this form.'
                        })
                      ]}
                    />
                    <PasswordStrengthBar
                      password={currentValues.formPassword}
                    />
                  </div>
                </div>
              )}
            <div class="field">
              <div class="control">
                <label class="label">
                  Display the document next to the form?
                </label>

                <Field
                  name="displayDocument"
                  component={ReSelect}
                  options={[
                    { value: true, label: 'Yes' },
                    {
                      value: false,
                      label: 'No'
                    }
                  ]}
                  validate={[]}
                />
              </div>
              <p class="help">
                If yes, the PDF and the selected fields will be displayed beside
                the form in a split-view mode.
              </p>
            </div>
            <label class="label">Form Title</label>
            <div className="field">
              <div class="control">
                <Field name="title" className="input" component="input" />
              </div>
            </div>

            <label class="label">Form Description</label>
            <div className="field">
              <div class="control">
                <Field name="description" component={TinyEditor} />
              </div>
              <p class="help ">
                This description is shown to the user on the top of the form. It
                can be plain text or HTML.
              </p>
            </div>
            <CollapsibleCard title="Customize Labels" id="additional-options">
              <div class="field">
                <label class="label">Submit Button Label</label>
                <div class="control">
                  <Field
                    name="submitLabel"
                    component={TextField}
                    defaultValue="Submit"
                  />
                </div>
                <p class="help ">
                  Text to be displayed on the submit button. Default is
                  "Submit".
                </p>
              </div>
              <div class="field">
                <label class="label">Required Field Label</label>
                <div class="control">
                  <Field
                    name="requiredLabel"
                    component={TextField}
                    defaultValue="required"
                  />
                </div>
                <p class="help ">
                  Text to be displayed after the label of required fields.
                  Default is "required".
                </p>
              </div>
            </CollapsibleCard>
          </div>
          <div style={currentTab === 'submission' ? {} : { display: 'none' }}>
            <div class="field">
              <label class="label">Message on Submission</label>

              <div class="control">
                <Field name="submissionMessage" component={TinyEditor} />
              </div>
              <p class="help ">
                This message is shown to the user after sumission instead of the
                default message. It can be plain text or HTML.
              </p>
            </div>
            <div className="field">
              <label class="label">Generated File Name </label>
              <p className="control">
                <Field
                  name="fileName"
                  component={FieldSelect}
                  enableCurrentStep={true}
                  width={x}
                  multiple=" "
                  options={prevData}
                  typeFilter="field"
                  validate={[validFileName]}
                />
              </p>
              <p class="help">
                File extension ".pdf" will be added automatically.
              </p>
            </div>
            <div class="field">
              <div class="control">
                <label class="label">
                  Show download button after submission?{' '}
                </label>
                <Field
                  name="download"
                  component={ReSelect}
                  options={[
                    { value: true, label: 'Yes' },
                    { value: false, label: 'No' }
                  ]}
                  validate={[]}
                />
              </div>
            </div>
            <div class="field">
              <label class="label">Redirect on Submission</label>
              <div class="control">
                <Field
                  name="submissionRedirect"
                  component={TextField}
                  validate={[
                    url({
                      msg: 'Please provide a valid URL (starting from http or https).',
                      allowBlank: true
                    })
                  ]}
                />
              </div>
              <p class="help ">
                Provide a URL to redirect the user to external page after
                submission instead of displaying a message.
              </p>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

const DocumentSettings = reduxForm({
  initialValues: {
    displayFields: { value: 'all', label: 'Display all fields' },
    access: {
      value: 'public',
      label: 'Anyone with the form link'
    },
    displayDocument: {
      value: false,
      label: 'No'
    },
    download: { value: false, label: 'No' }
  }
})(DocumentSettingsComponent);
export default DocumentSettings;
