import React from 'react';
import './ActionBar.scss';

const ActionBar = (props) => {
  const page = props.page;
  const pages = props.pages;
  const numPages = pages ? pages.length : 0;

  return (
    <div class="action-bar-container">
      <nav className="action-menu">
        <div className="action-menu-item" onClick={props.onLeftIconClick}>
          <div className="level-item">
            <span className="icon is-medium" style={{ cursor: 'pointer' }}>
              <i className="far fa-lg fa-arrow-alt-circle-up"></i>
            </span>
          </div>
        </div>
        <div className="action-menu-item" onClick={props.onRightIconClick}>
          <div className="level-item">
            <span className="icon is-medium" style={{ cursor: 'pointer' }}>
              <i className="far fa-lg fa-arrow-alt-circle-down"></i>
            </span>
          </div>
        </div>
        <div className="action-menu-item">
          <div className="level-item sub-item">{page}</div>
          <div className="level-item sub-item">/</div>
          <div className="level-item sub-item">{numPages}</div>
        </div>
        <div class="border-vertical"></div>
        <div className="action-menu-item" onClick={props.onDisableDrag}>
          <div className="level-item">
            <span className="icon is-medium" style={{ cursor: 'pointer' }}>
              <i
                className={`fas fa-mouse-pointer ${
                  !props.isImageMoveable ? 'icon-highlight' : ''
                }`}
              ></i>
            </span>
          </div>
        </div>
        <div className="action-menu-item" onClick={props.onToggleDrag}>
          <div className="level-item">
            <span className="icon is-medium" style={{ cursor: 'pointer' }}>
              <i
                className={`far fa-hand-paper ${
                  props.isImageMoveable ? 'icon-highlight' : ''
                }`}
              ></i>
            </span>
          </div>
        </div>
        <div className="action-menu-item" onClick={props.onZoomOut}>
          <div className="level-item">
            <span className="icon is-medium" style={{ cursor: 'pointer' }}>
              <i className="fas fa-minus"></i>
            </span>
          </div>
        </div>
        <div className="action-menu-item" onClick={props.onZoomIn}>
          <div className="level-item">
            <span className="icon is-medium" style={{ cursor: 'pointer' }}>
              <i className="fas fa-plus"></i>
            </span>
          </div>
        </div>
        <div className="action-menu-item">
          <div className="level-item sub-item">
            {`${(props.scale * 100).toFixed(2)} %`}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default ActionBar;
