import { useFormik } from 'formik';
import React, { useState } from 'react';
import Modal from 'react-responsive-modal';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

const CreateWorkflowModal = ({ open, onClose, onSubmission, workflows }) => {
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Workflow name must be at least 2 characters')
      .max(64, 'Workflow name must be less than 65 characters')
      .matches(
        /^[\w\-\s]+$/,
        'Workflow name can only contain letters, numbers, underscores and hyphens'
      )
      .required('Workflow name is required')
      // name cannot be same as the name of any existing workflows
      .test('name', 'Workflow with this name already exists!', (name) => {
        const workflowNames = workflows.map((w) => w.name.toLowerCase()) || [];
        const lowerName = name ? name.toLowerCase() : '';
        if (workflowNames.includes(lowerName)) {
          return false;
        }
        return true;
      })
  });
  const formik = useFormik({
    initialValues: {
      name: ''
    },
    validationSchema,
    onSubmit: async ({ name }) => {
      try {
        setLoading(true);
        onSubmission(name);
        setLoading(false);
        onClose();
      } catch (error) {
        console.log(error);
        toast.info('Something went wrong!');
        onClose();
      }
    }
  });

  const [loading, setLoading] = useState(false);

  return (
    <Modal open={open} onClose={onClose} closeOnOverlayClick={false}>
      <div class="modal_top">
        <h3 class="title is-h3">Create New Workflow</h3>
        <form onSubmit={formik.handleSubmit}>
          <div class="field">
            <div class="control">
              <input
                class="input is-small"
                type="text"
                name="name"
                placeholder="Workflow Name..."
                value={formik.values.name}
                onChange={formik.handleChange}
              />
            </div>
            {formik.errors.name && (
              <p class="help is-danger">{formik.errors.name}</p>
            )}
          </div>
          <hr />
          <div class="field is-grouped">
            <div class="control">
              <button
                type="sbumit"
                class={`button is-info is-small ${loading ? 'is-loading' : ''}`}
              >
                Create Workflow
              </button>
            </div>
            <div class="control">
              <button
                type="button"
                onClick={onClose}
                class="button is-info is-small is-light"
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default CreateWorkflowModal;
