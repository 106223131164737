import server from 'api/server';
import React, { useState } from 'react';
import Modal from 'react-responsive-modal';
import Select from 'react-select';
import { toast } from 'react-toastify';

const AddModal = ({ open, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [authUri, setAuthUri] = useState(null);
  const [accounts, setAccounts] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [form, setFrom] = useState('addIntegration');
  const [error, setError] = useState('');

  const onAddAccount = async () => {
    try {
      setError('');
      setLoading(true);
      const { data } = await server.post('apps/smartvault', { email });
      setLoading(false);
      if (!data.exists) {
        setError('Cannot find a SmartVault user with this email address!');
        setEmail('');
        return;
      } else if (!data.authorized) {
        setAuthUri(data.authorizationUri);
        setFrom('authorize');
      } else if (!data.accountSelected) {
        setAccounts(data.accounts);
        setFrom('selectAccount');
      } else {
        onClose();
        setFrom('addIntegration');
        setAuthUri(null);
        toast.info('SmartVault account added successfully!');
      }
    } catch (err) {
      setLoading(false);
      onClose();
      if (err.response && err.response.data && err.response.data.message) {
        console.log(err.response.data.message);
      }
      toast.info('Could not add SmartVault account!');
      console.log(err);
    }
  };

  const onVerify = async () => {
    try {
      setError('');
      setLoading(true);
      const { data } = await server.post('apps/smartvault', { email });
      setLoading(false);
      if (data.authorized && data.accountSelected) {
        setFrom('addIntegration');
        setEmail('');
        setAuthUri(null);
        onClose();
        toast.info('SmartVault account added successfully!');
      } else if (data.accounts) {
        setAccounts(data.accounts);
        setFrom('selectAccount');
      } else {
        setError(
          'Docdown is not authorized to access your SmartVault account yet!'
        );
      }
    } catch (err) {
      setLoading(false);
      onClose();
      if (err.response && err.response.data && err.response.data.message) {
        console.log(err.response.data.message);
      }
      toast.info('Could not add SmartVault account!');
      console.log(err);
    }
  };

  const onAccountSelect = async () => {
    try {
      if (selectedAccount) setError('');
      else {
        setError('Please select an account');
        return;
      }
      const account = accounts.find((a) => a.id == selectedAccount.value);
      setLoading(true);
      const { data } = await server.post('apps/smartvault', { email, account });
      if (data.authorized && data.accountSelected) {
        setEmail('');
        setAuthUri(null);
        setLoading(false);
        onClose();
        setFrom('addIntegration');
        toast.info('SmartVault account added successfully!');
      } else {
        throw Error('Could not add Smartvault Account');
      }
    } catch (err) {
      setLoading(false);
      onClose();
      if (err.response && err.response.data && err.response.data.message) {
        console.log(err.response.data.message);
      }
      toast.info('Could not add SmartVault account!');
      console.log(err);
    }
  };

  const renderAddIntegration = () => {
    return (
      <div class="modal_top">
        <h3 class="title is-h3">Add SmartVault Account</h3>
        <p>Enter your SmartVault account email address.</p>
        <div class="field">
          <label class="label">Email</label>
          <div class="control">
            <input
              class="input is-small"
              type="email"
              placeholder="Email address..."
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          {error && <p class="help is-danger">{error}</p>}
        </div>
        <hr />
        <div class="field is-grouped">
          <div class="control">
            <button
              onClick={onAddAccount}
              class={`button is-info is-small ${loading ? 'is-loading' : ''}`}
            >
              Confirm
            </button>
          </div>
          <div class="control">
            <button
              onClick={() => {
                setError('');
                onClose();
              }}
              class="button is-info is-small is-light"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderAuthorization = () => {
    return (
      <div class="modal_top">
        <h3 class="title is-h3">Authenticate SmartVault Account</h3>
        <p>
          Please click on the following button to allow Docdown to access your
          SmartVault account.
        </p>
        <hr />
        <div class="field is-grouped">
          <div class="control">
            <button
              onClick={() => {
                window.open(authUri, '_blank').focus();
                setFrom('verify');
              }}
              class={`button is-info is-small ${loading ? 'is-loading' : ''}`}
            >
              Authenticate Docdown
            </button>
          </div>
          <div class="control"></div>
        </div>
      </div>
    );
  };
  const renderVerify = () => {
    return (
      <div class="modal_top">
        <h3 class="title is-h3">Verify SmartVault Account</h3>
        <p>
          After allowing Docdown to access your account, please click on the
          verify button below.
        </p>
        {error && <p class="help is-danger">{error}</p>}
        <hr />
        <div class="field is-grouped">
          <div class="control">
            <button
              onClick={onVerify}
              class={`button is-info is-small ${loading ? 'is-loading' : ''}`}
            >
              Verify
            </button>
          </div>
          <div class="control"></div>
        </div>
      </div>
    );
  };
  const renderSelectAccount = () => {
    return (
      <div class="modal_top">
        <h3 class="title is-h3">Select SmartVault Account</h3>
        <p>
          This user is connected to multiple SmartVault accounts. Please select
          the account you want to use in this integration.
        </p>
        <Select
          options={accounts.map((a) => ({ value: a.id, label: a.name }))}
          onChange={setSelectedAccount}
        />
        {error && <p class="help is-danger">{error}</p>}
        <hr />
        <div class="field is-grouped">
          <div class="control">
            <button
              onClick={onAccountSelect}
              class={`button is-info is-small ${loading ? 'is-loading' : ''}`}
            >
              Select
            </button>
          </div>
          <div class="control"></div>
        </div>
      </div>
    );
  };

  return (
    <Modal open={open} onClose={onClose} closeOnOverlayClick={false}>
      {form === 'addIntegration' && renderAddIntegration()}
      {form === 'authorize' && renderAuthorization()}
      {form === 'verify' && renderVerify()}
      {form === 'selectAccount' && renderSelectAccount()}
    </Modal>
  );
};
export default AddModal;
