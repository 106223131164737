import React from 'react';

export const getAppLogo = (type) => {
  switch (type) {
    case 'gmail':
      return '/assets/images/gmail.png';
    case 'drive':
      return '/assets/images/drive.png';
    case 'customEmail':
      return '/assets/images/gmail.png';
    case 'smartvault':
      return '/assets/images/smartvault-logo.png';
  }
};

export const getAppMeta = (type) => {
  switch (type) {
    case 'gmail':
      return {
        label: 'Gmail',
        logo: <img src={getAppLogo(type)} />,
        settings: false
      };
    case 'drive':
      return {
        label: 'Drive',
        logo: <img src={getAppLogo(type)} />,
        settings: false
      };
    case 'customEmail':
      return {
        label: 'Custom Email',
        logo: <img src={getAppLogo(type)} />,
        settings: true
      };
    case 'smartvault':
      return {
        label: 'SmartVault',
        logo: <img src={getAppLogo(type)} />,
        settings: false
      };
    default:
      return null;
  }
};
export const getStatusTag = (status) => {
  switch (status) {
    case 'active':
      return <span class="tag is-success is-light">Verified</span>;
    case 'unverified':
      return <span class="tag is-danger is-light">Unverified</span>;
    default:
      return null;
  }
};
