import { useTextWidth } from '@imagemarker/use-text-width';
import FontPicker from 'font-picker-react';
import useDimensions from 'hooks/useDimensions';
import React, { useEffect, useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import sanitizeHtml from 'sanitize-html';
import uniqid from 'uniqid';
import './SignatureField.scss';
import Upload from './Upload';

function getTextWidth(font, size, measureText) {
  const text = document.createElement('span');
  document.body.appendChild(text);

  text.style.font = font;
  text.style.fontSize = size + 'px';
  text.style.height = 'auto';
  text.style.width = 'auto';
  text.style.position = 'absolute';
  text.style.whiteSpace = 'no-wrap';
  text.innerHTML = measureText;

  const width = Math.ceil(text.clientWidth);
  text.remove();
  return width + 2 * size;
}

const translate = (text, lang) => {
  const map = {
    draw: {
      en: 'Draw',
      es: 'Dibujar',
      fr: 'Dessiner',
      de: 'Zeichnen',
      pt: 'Empate'
    },
    type: {
      en: 'Type',
      es: 'Escribe',
      fr: 'Taper',
      de: 'Typ',
      pt: 'Modelo'
    },
    upload: {
      en: 'Upload',
      es: 'Subir',
      fr: 'Télécharger',
      de: 'Hochladen',
      pt: 'Envio'
    },
    consent: {
      en: `By signing with an electronic signature, I agree that such signature will be as valid as handwritten signatures and considered originals to the extent allowed by applicable law.`,
      es: `Al firmar con una firma electrónica, acepto que dicha firma será tan válida como las firmas manuscritas y se considerará originales en la medida en que lo permita la ley aplicable.`,
      fr: `En signant avec une signature électronique, j'accepte que cette signature soit aussi valable que les signatures manuscrites et considérées comme des originaux dans la mesure permise par la loi applicable.`,
      de: `Durch das Unterschreiben mit einer elektronischen Signatur erkläre ich mich damit einverstanden, dass diese Unterschrift so gültig ist wie handschriftliche Unterschriften und im gesetzlich zulässigen Umfang als Originale betrachtet werden.`,
      pt: `Ao assinar com uma assinatura eletrônica, concordo que tal assinatura será tão válida quanto as assinaturas manuscritas e consideradas originais na medida permitida pela lei aplicável.`
    },
    'full-name': {
      en: 'Full legal name',
      es: 'Nombre legal completo',
      fr: 'Nom légal complet',
      de: 'Vollständiger rechtlicher Name',
      pt: 'Nome legal completo'
    },
    email: {
      en: 'Email',
      es: 'Email',
      fr: 'Email',
      de: 'Email',
      pt: 'Email'
    },
    'type-here': {
      en: 'Type Here',
      es: 'Escriba aquí',
      fr: 'Écrivez ici',
      de: 'Geben Sie hier ein',
      pt: 'Digite aqui'
    }
  };
  if (lang) return map[text][lang];
  else return map[text]['en'];
};

const SignatureField = (props) => {
  const { value, onChange } = props.input;

  let tabs = props.tabs || 'all';

  const [tab, setTab] = useState(tabs === 'all' ? 'draw' : tabs);
  const [drawn, setDrawn] = useState(null);
  const [trimmed, setTrimmed] = useState(null);
  const [typed, setTyped] = useState(null);
  const [typedImage, setTypedImage] = useState(null);
  const [uploaded, setUploaded] = useState(null);
  const [signerName, setName] = useState(null);
  const [signerEmail, setEmail] = useState(null);

  const [id] = useState(uniqid());

  const [font, setFont] = useState('Alex Brush');

  let sigCanvas = {};

  const [ref, { width }] = useDimensions(true);

  useEffect(() => {
    if (sigCanvas && sigCanvas.fromDataURL) {
      // if (drawn) sigCanvas.fromDataURL(drawn);
    }
  });
  useEffect(() => {
    props.focus();
  }, [value, tab]);
  const textRef = useRef();
  const textWidth = useTextWidth({ ref: textRef });
  useEffect(() => {
    let canvas = document.getElementById('signature-typed-canvas-' + id);
    if (canvas && typed) {
      const twidth = getTextWidth(font, 48, typed);
      let canvasTxt = canvas.getContext('2d');
      canvasTxt.canvas.width = twidth || 300;
      canvasTxt.font = `48px ${font}`;
      canvasTxt.fillText(typed, 10, 60);
      setTypedImage(canvasTxt.canvas.toDataURL());
    }
  }, [font, textWidth, typed]);

  useEffect(() => {
    if (tab === 'draw')
      onChange({
        authFields: props.authFields,
        signerName,
        signerEmail,
        signature: trimmed
      });
    if (tab === 'type') {
      onChange({
        authFields: props.authFields,
        signerName,
        signerEmail,
        signature: typedImage
      });
    }
    if (tab === 'upload')
      onChange({
        authFields: props.authFields,
        signerName,
        signerEmail,
        signature: uploaded
      });
  }, [tab, drawn, typed, uploaded, signerEmail, signerName]);

  const {
    meta: { touched, error, warning }
  } = props;
  return (
    <div
      className={`signature_field auth_${props.authFields}`}
      style={props.hidden ? { display: 'none' } : {}}
      ref={ref}
    >
      <div class="field">
        <label className="label ">
          {props.label || 'Signature'}{' '}
          {props.required && (
            <span className="has-text-grey">
              ({props.requiredLabel || 'required'})
            </span>
          )}
        </label>
        <div class="tabs">
          <ul>
            {(tabs === 'all' || tabs === 'draw') && (
              <li class={`${tab === 'draw' ? 'is-active' : ''}`}>
                <a onClick={() => setTab('draw')}>
                  {translate('draw', props.language)}
                </a>
              </li>
            )}
            {(tabs === 'all' || tabs === 'type') && (
              <li class={`${tab === 'type' ? 'is-active' : ''}`}>
                <a onClick={() => setTab('type')}>
                  {translate('type', props.language)}
                </a>
              </li>
            )}
            {(tabs === 'all' || tabs === 'upload') && (
              <li class={`${tab === 'upload' ? 'is-active' : ''}`}>
                <a onClick={() => setTab('upload')}>
                  {translate('upload', props.language)}
                </a>
              </li>
            )}
          </ul>
        </div>

        {tab === 'draw' && (
          <div>
            <button
              type="button"
              className="signature_clear button  is-fullwidth"
              onClick={(e) => {
                sigCanvas.clear();
                setTrimmed(null);
                setDrawn(null);
              }}
            >
              <i class="far fa-trash-alt" />
            </button>

            <SignatureCanvas
              className="signature_canvas"
              disabled={props.static ? true : false}
              ref={(ref) => {
                sigCanvas = ref;
              }}
              onEnd={(e) => {
                const canvas = sigCanvas;
                if (canvas) {
                  setTrimmed(canvas.getTrimmedCanvas().toDataURL('image/png'));
                  setDrawn(canvas.getCanvas().toDataURL('image/png'));
                }
                props.focus();
                // canvas.clear();
              }}
              canvasProps={{
                width: width,
                height: 180,
                className: 'is-fullwidth form_sigCanvas'
              }}
              backgroundColor="rgba(255,255,255, 0.0)"
            />
          </div>
        )}

        {tab === 'type' && (
          <div class="type-sign">
            <FontPicker
              //DOC: https://www.npmjs.com/package/font-picker-react
              apiKey="AIzaSyBQErxcyjsmUxXTtKcM0bTriT124R69Pks"
              pickerId={'sign' + id} // main for css class apply-font-main
              class={'signature-font-picker'}
              activeFontFamily={font}
              onChange={(nextFont) => setFont(nextFont.family)}
              families={['Alex Brush', 'Dancing Scripts', 'Pacifico']}
              // categories={['handwriting']}
            />
            <canvas
              id={`signature-typed-canvas-${id}`}
              height="100"
              style={{ display: 'none', padding: '5px' }}
            />
            <p
              ref={textRef}
              style={{
                fontFamily: font,
                fontSize: '52px',
                display: 'none'
              }}
            >
              {typed}
            </p>
            <input
              class={`input signature-type apply-font-sign${id}`}
              id={`signature-typed-${id}`}
              type="text"
              value={typed}
              onChange={(e) => {
                if (!e.target.value) {
                  setTyped(null);
                  setTypedImage(null);
                } else {
                  setTyped(
                    sanitizeHtml(e.target.value, {
                      allowedTags: [],
                      allowedAttributes: {}
                    })
                  );
                }
              }}
              placeholder={translate('type-here', props.language)}
            />
          </div>
        )}
        {tab === 'upload' && (
          <div class="signature-upload">
            <Upload
              language={props.language}
              value={uploaded}
              onClear={() => setUploaded(null)}
              onUpload={(val) => (val ? setUploaded(val.base64) : null)}
            />
          </div>
        )}

        {props.authFields && props.authFields === 'nameEmail' && (
          <div class="field is-horizontal">
            <div class="field-body">
              <div class="field signerNameInput">
                <p class="control is-expanded has-icons-left">
                  <input
                    class="input"
                    type="text"
                    placeholder={translate('full-name', props.language)}
                    value={signerName}
                    onChange={(e) => {
                      setName(e.target.value);
                      props.focus();
                    }}
                    onFocus={props.focus}
                  />
                  <span class="icon is-small is-left">
                    <i class="fas fa-user"></i>
                  </span>
                </p>
              </div>
              <div class="field signerEmailInput">
                <p class="control is-expanded has-icons-left has-icons-right">
                  <input
                    class="input"
                    type="email"
                    placeholder={translate('email', props.language)}
                    value={signerEmail}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      props.focus();
                    }}
                    onFocus={props.focus}
                  />
                  <span class="icon is-small is-left">
                    <i class="fas fa-envelope"></i>
                  </span>
                </p>
              </div>
            </div>
          </div>
        )}

        <p className="help signature_consent">
          {translate('consent', props.language)}
        </p>
        <p className="help is-danger">
          {touched &&
            ((error && <span>{error}</span>) ||
              (warning && <span>{warning}</span>))}
          {props.warning
            ? props.warnings.map((warning, i) => <span>{warning}</span>)
            : null}
        </p>
        {props.description && <p className="help">{props.description}</p>}
      </div>
    </div>
  );
};
export default SignatureField;
