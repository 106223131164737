import server from 'api/server';
import CopyButton from 'components/_common/CopyButton';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import './../App.scss';

const CustomEmailSettings = ({ data }) => {
  const [app, setApp] = useState(data);
  const [verifying, setVerifying] = useState(false);
  return (
    <>
      <nav class="level">
        <div class="level-left">
          <div class="level-item">
            <h6 class="title is-6">Authenticate Domain</h6>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <button
              onClick={async () => {
                setVerifying(true);
                try {
                  const { data } = await server.post(`/apps/email/verify`, {
                    id: app.id
                  });
                  if (
                    data.data &&
                    data.data.domain &&
                    data.data.domain.state &&
                    data.data.domain.state === 'active'
                  )
                    toast.info('Domain successfully authenticated!');
                  setApp(data);
                } catch (err) {
                  console.log(err);
                }
                setVerifying(false);
              }}
              class={`button is-small is-info is-outlined ${
                verifying ? 'is-loading' : ''
              }`}
            >
              Verify DNS Settings
            </button>
          </div>
        </div>
      </nav>
      <p>
        Give Docdown permission to send emails on your behalf by authenticating
        this domain. Go to the DNS provider that you use to manage{' '}
        {app.data.domain.name} and add the following DNS records.
      </p>
      {/* <div class="table-container"> */}
      <table class="table is-fullwidth">
        <thead>
          <tr>
            <th>Type</th>
            <th>Hostname</th>
            <th>Enter This Value</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{app.data.sending_dns_records[0].record_type}</td>
            <td>{app.data.sending_dns_records[0].name}</td>
            <td>
              <div class="dns_value">
                <pre>v=spf1 include:email.docdown.io ~all</pre>
                <CopyButton text={'v=spf1 include:email.docdown.io ~all'} />
              </div>
            </td>
            <td>
              {app.data.sending_dns_records[0].valid === 'valid' ? (
                <span class="tag is-success">Authenticated</span>
              ) : (
                <span class="tag is-warning">Unauthenticated</span>
              )}
            </td>
          </tr>
          <tr>
            <td>{app.data.sending_dns_records[1].record_type}</td>
            <td>{app.data.sending_dns_records[1].name}</td>
            <td>
              <div class="dns_value">
                <pre>{app.data.sending_dns_records[1].value}</pre>
                <CopyButton text={app.data.sending_dns_records[1].value} />
              </div>
            </td>
            <td>
              {app.data.sending_dns_records[1].valid === 'valid' ? (
                <span class="tag is-success">Authenticated</span>
              ) : (
                <span class="tag is-warning">Unauthenticated</span>
              )}
            </td>
          </tr>
        </tbody>
      </table>
      {/* </div> */}
    </>
  );
};

export default CustomEmailSettings;
